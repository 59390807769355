export const rarities = [
    "Plentiful",
    "Common",
    "Uncommon",
    "Limited",
    "Rare"
]

export const rarityTable = [
    [ 0.5,  0.3,  0.15, 0.03, 0.01 ], //plentiful
    [ 0.7,  0.5,  0.25, 0.1,  0.01 ],
    [ 0.85, 0.75, 0.5,  0.15, 0.02 ],
    [ 0.97, 0.9,  0.85, 0.5,  0.03 ],
    [ 0.99, 0.99, 0.98, 0.97, 0.5  ] //rare
]

export const breeds = {
    1: { name: "Fae",           rarity: 0, isAncient: false },
    2: { name: "Guardian",      rarity: 0, isAncient: false },
    3: { name: "Mirror",        rarity: 0, isAncient: false },
    4: { name: "Pearlcatcher",  rarity: 1, isAncient: false },
    5: { name: "Ridgeback",     rarity: 1, isAncient: false },
    6: { name: "Tundra",        rarity: 0, isAncient: false },
    7: { name: "Spiral",        rarity: 1, isAncient: false },
    8: { name: "Imperial",      rarity: 3, isAncient: false },
    9: { name: "Snapper",       rarity: 1, isAncient: false },
    10: { name: "Wildclaw",     rarity: 4, isAncient: false },
    11: { name: "Nocturne",     rarity: 3, isAncient: false },
    12: { name: "Coatl",        rarity: 4, isAncient: false },
    13: { name: "Skydancer",    rarity: 2, isAncient: false },
    14: { name: "Bogsneak",     rarity: 2, isAncient: false },
    15: { name: "Obelisk",      rarity: 2, isAncient: false },
    16: { name: "Fathom",       rarity: 2, isAncient: false },
    17: { name: "Gaoler",       rarity: 1, isAncient: true },
    18: { name: "Banescale",    rarity: 1, isAncient: true },
    19: { name: "Veilspun",     rarity: 1, isAncient: true },
    20: { name: "Aberration",   rarity: 1, isAncient: true },
    21: { name: "Undertide",    rarity: 1, isAncient: true },
    22: { name: "Aether",       rarity: 1, isAncient: true },
    23: { name: "Sandsurge",    rarity: 1, isAncient: true },
    24: { name: "Auraboa",      rarity: 1, isAncient: true },
    25: { name: "Dusthide",     rarity: 1, isAncient: true },
    26: { name: "Everlux",      rarity: 1, isAncient: true },
}

export const primaryGenes = {
    0: { name: "Basic",                     breed: 0,   rarity: 0 },
    1: { name: "Iridescent",                breed: 0,   rarity: 4 },
    2: { name: "Tiger",                     breed: 0,   rarity: 1 },
    3: { name: "Clown",                     breed: 0,   rarity: 1 },
    4: { name: "Speckle",                   breed: 0,   rarity: 1 },
    5: { name: "Ripple",                    breed: 0,   rarity: 2 },
    6: { name: "Bar",                       breed: 0,   rarity: 2 },
    7: { name: "Crystal",                   breed: 0,   rarity: 4 },
    8: { name: "Vipera",                    breed: 0,   rarity: 2 },
    9: { name: "Piebald",                   breed: 0,   rarity: 1 },
    10: { name: "Cherub",                   breed: 0,   rarity: 2 },
    11: { name: "Poison",                   breed: 0,   rarity: 3 },
    12: { name: "Giraffe",                  breed: 0,   rarity: 2 },
    13: { name: "Petals",                   breed: 0,   rarity: 4 },
    14: { name: "Jupiter",                  breed: 0,   rarity: 2 },
    15: { name: "Skink",                    breed: 0,   rarity: 3 },
    16: { name: "Falcon",                   breed: 0,   rarity: 1 },
    17: { name: "Metallic",                 breed: 0,   rarity: 4 },
    18: { name: "Savanna",                 breed: 0,   rarity: 1 },
    19: { name: "Jaguar",                   breed: 0,   rarity: 2 },
    20: { name: "Wasp",                     breed: 0,   rarity: 4 },
    21: { name: "Tapir",                    breed: 0,   rarity: 1 },
    22: { name: "Pinstripe",                breed: 0,   rarity: 3 },
    23: { name: "Python",                   breed: 0,   rarity: 3 },
    24: { name: "Starmap",                  breed: 0,   rarity: 4 },
    25: { name: "Lionfish",                 breed: 0,   rarity: 2 },
    26: { name: "Laced",                    breed: 0,   rarity: 1 },
    27: { name: "Giraffe (Gaoler)",         breed: 17,  rarity: 2 },
    28: { name: "Wasp (Gaoler)",            breed: 17,  rarity: 4 },
    29: { name: "Shaggy (Gaoler)",          breed: 17,  rarity: 1 },
    30: { name: "Falcon (Gaoler)",          breed: 17,  rarity: 1 },
    31: { name: "Piebald (Gaoler)",         breed: 17,  rarity: 1 },
    32: { name: "Pinstripe (Gaoler)",       breed: 17,  rarity: 3 },
    33: { name: "Jaguar (Gaoler)",          breed: 17,  rarity: 2 },
    34: { name: "Bar (Gaoler)",             breed: 17,  rarity: 2 },
    35: { name: "Tapir (Gaoler)",           breed: 17,  rarity: 1 },
    36: { name: "Tiger (Gaoler)",           breed: 17,  rarity: 1 },
    37: { name: "Crystal (Gaoler)",         breed: 17,  rarity: 4 },
    38: { name: "Mosaic (Gaoler)",          breed: 17,  rarity: 2 },
    39: { name: "Phantom (Gaoler)",         breed: 17,  rarity: 3 },
    40: { name: "Leopard",                  breed: 0,   rarity: 1 },
    41: { name: "Slime",                    breed: 0,   rarity: 3 },
    42: { name: "Fade",                     breed: 0,   rarity: 1 },
    43: { name: "Cherub (Banescale)",       breed: 18,  rarity: 2 },
    44: { name: "Jaguar (Banescale)",       breed: 18,  rarity: 2 },
    45: { name: "Pinstripe (Banescale)",    breed: 18,  rarity: 3 },
    46: { name: "Tiger (Banescale)",        breed: 18,  rarity: 1 },
    47: { name: "Marble (Banescale)",       breed: 18,  rarity: 1 },
    48: { name: "Laced (Banescale)",        breed: 18,  rarity: 1 },
    49: { name: "Metallic (Banescale)",     breed: 18,  rarity: 4 },
    50: { name: "Savanna (Banescale)",     breed: 18,  rarity: 1 },
    51: { name: "Petals (Banescale)",       breed: 18,  rarity: 4 },
    52: { name: "Skink (Banescale)",        breed: 18,  rarity: 3 },
    53: { name: "Poison (Banescale)",       breed: 18,  rarity: 3 },
    54: { name: "Chevron (Banescale)",      breed: 18,  rarity: 2 },
    55: { name: "Candycane (Banescale)",    breed: 18,  rarity: 3 },
    56: { name: "Ragged (Banescale)",       breed: 18,  rarity: 2 },
    57: { name: "Swirl",                    breed: 0,   rarity: 1 },
    58: { name: "Mosaic",                   breed: 0,   rarity: 2 },
    59: { name: "Stitched",                 breed: 0,   rarity: 3 },
    60: { name: "Fade (Veilspun)",          breed: 19,  rarity: 1 },
    61: { name: "Laced (Veilspun)",         breed: 19,  rarity: 1 },
    62: { name: "Tapir (Veilspun)",         breed: 19,  rarity: 1 },
    63: { name: "Vipera (Veilspun)",        breed: 19,  rarity: 2 },
    64: { name: "Jupiter (Veilspun)",       breed: 19,  rarity: 2 },
    65: { name: "Starmap (Veilspun)",       breed: 19,  rarity: 4 },
    66: { name: "Stitched (Veilspun)",      breed: 19,  rarity: 4 },
    67: { name: "Skink (Veilspun)",         breed: 19,  rarity: 3 },
    68: { name: "Wasp (Veilspun)",          breed: 19,  rarity: 4 },
    69: { name: "Bright (Veilspun)",        breed: 19,  rarity: 2 },
    70: { name: "Arc (Veilspun)",           breed: 19,  rarity: 2 },
    71: { name: "Shell (Veilspun)",         breed: 19,  rarity: 2 },
    72: { name: "Sphinxmoth (Veilspun)",    breed: 19,  rarity: 2 },
    73: { name: "Laced (Gaoler)",           breed: 17,  rarity: 1 },
    74: { name: "Tapir (Banescale)",        breed: 18,  rarity: 1 },
    75: { name: "Clown (Banescale)",        breed: 18,  rarity: 1 },
    76: { name: "Clown (Veilspun)",         breed: 19,  rarity: 1 },
    77: { name: "Clown (Gaoler)",           breed: 17,  rarity: 1 },
    78: { name: "Ripple (Gaoler)",          breed: 17,  rarity: 2 },
    79: { name: "Ripple (Banescale)",       breed: 18,  rarity: 2 },
    80: { name: "Falcon (Banescale)",       breed: 18,  rarity: 1 },
    81: { name: "Giraffe (Banescale)",      breed: 18,  rarity: 2 },
    82: { name: "Flaunt",                   breed: 0,   rarity: 2 },
    83: { name: "Giraffe (Veilspun)",       breed: 19,  rarity: 2 },
    84: { name: "Ribbon",                   breed: 0,   rarity: 2 },
    85: { name: "Fade (Banescale)",         breed: 18,  rarity: 1 },
    86: { name: "Fade (Gaoler)",            breed: 17,  rarity: 1 },
    87: { name: "Pharaoh",                  breed: 0,   rarity: 4 },
    88: { name: "Ground",                   breed: 0,   rarity: 3 },
    89: { name: "Bar (Aberration)",         breed: 20,  rarity: 2 },
    90: { name: "Fade (Aberration)",        breed: 20,  rarity: 1 },
    91: { name: "Crystal (Aberration)",     breed: 20,  rarity: 4 },
    92: { name: "Falcon (Aberration)",      breed: 20,  rarity: 1 },
    93: { name: "Diamond (Aberration)",     breed: 20,  rarity: 3 },
    94: { name: "Giraffe (Aberration)",     breed: 20,  rarity: 2 },
    95: { name: "Tapir (Aberration)",       breed: 20,  rarity: 1 },
    96: { name: "Vipera (Aberration)",      breed: 20,  rarity: 2 },
    97: { name: "Ground (Aberration)",      breed: 20,  rarity: 3 },
    98: { name: "Speckle (Aberration)",     breed: 20,  rarity: 1 },
    99: { name: "Jaguar (Aberration)",      breed: 20,  rarity: 2 },
    100: { name: "Lionfish (Aberration)",   breed: 20,  rarity: 2 },
    101: { name: "Pharaoh (Aberration)",    breed: 20,  rarity: 4 },
    102: { name: "Orb (Aberration)",        breed: 20,  rarity: 3 },
    103: { name: "Savanna (Aberration)",   breed: 20,  rarity: 1 },
    104: { name: "Swirl (Aberration)",      breed: 20,  rarity: 1 },
    105: { name: "Ribbon (Aberration)",     breed: 20,  rarity: 2 },
    106: { name: "Slime (Aberration)",      breed: 20,  rarity: 3 },
    107: { name: "Stitched (Aberration)",   breed: 20,  rarity: 3 },
    108: { name: "Wasp (Aberration)",       breed: 20,  rarity: 4 },
    109: { name: "Leopard (Banescale)",     breed: 18,  rarity: 1 },
    110: { name: "Boulder",                 breed: 0,   rarity: 3 },
    111: { name: "Flaunt (Gaoler)",         breed: 17,  rarity: 2 },
    112: { name: "Flaunt (Aberration)",     breed: 20,  rarity: 2 },
    113: { name: "Tide (Undertide)",        breed: 21,  rarity: 3 },
    114: { name: "Tide",                    breed: 0,   rarity: 3 },
    115: { name: "Fade (Undertide)",        breed: 21,  rarity: 1 },
    116: { name: "Wolf (Undertide)",        breed: 21,  rarity: 2 },
    117: { name: "Bar (Undertide)",         breed: 21,  rarity: 2 },
    118: { name: "Crystal (Undertide)",     breed:21,   rarity: 4 },
    119: { name: "Cherub (Undertide)",      breed: 21,  rarity: 2 },
    120: { name: "Pharaoh (Undertide)",     breed: 21,  rarity: 4 },
    121: { name: "Pinstripe (Undertide)",   breed: 21,  rarity: 3 },
    122: { name: "Falcon (Undertide)",      breed: 21,  rarity: 1 },
    123: { name: "Giraffe (Undertide)",     breed: 21,  rarity: 2 },
    124: { name: "Ribbon (Undertide)",      breed: 21,  rarity: 2 },
    125: { name: "Wasp (Undertide)",        breed: 21,  rarity: 4 },
    126: { name: "Lionfish (Undertide)",    breed: 21,  rarity: 2 },
    127: { name: "Checkers (Undertide)",    breed: 21,  rarity: 1 },
    128: { name: "Boa (Undertide)",         breed: 21,  rarity: 2 },
    129: { name: "Savanna (Undertide)",    breed: 21,   rarity: 1 },
    130: { name: "Ripple (Undertide)",      breed: 21,  rarity: 2 },
    131: { name: "Poison (Undertide)",      breed: 21,  rarity: 3 },
    132: { name: "Speckle (Undertide)",     breed: 21,  rarity: 1 },
    133: { name: "Octopus (Undertide)",     breed: 21,  rarity: 3 },
    134: { name: "Swirl (Undertide)",       breed: 21,  rarity: 1 },
    135: { name: "Boulder (Undertide)",     breed: 21,  rarity: 3 },
    136: { name: "Fern",                    breed: 0,   rarity: 3 },
    137: { name: "Fern (Banescale)",        breed: 18,  rarity: 3 },
    138: { name: "Fern (Veilspun)",         breed: 19,  rarity: 3 },
    139: { name: "Falcon (Veilspun)",       breed: 19,  rarity: 1 },
    140: { name: "Poison (Veilspun)",       breed: 19,  rarity: 3 },
    141: { name: "Slime (Veilspun)",        breed: 19,  rarity: 3 },
    142: { name: "Leopard (Veilspun)",      breed: 19,  rarity: 1 },
    143: { name: "Petals (Veilspun)",       breed: 19,  rarity: 4 },
    144: { name: "Speckle (Veilspun)",      breed: 19,  rarity: 1 },
    145: { name: "Bar (Veilspun)",          breed: 19,  rarity: 2 },
    146: { name: "Crystal (Veilspun)",      breed: 19,  rarity: 4 },
    147: { name: "Speckle (Banescale)",     breed: 18,  rarity: 1 },
    148: { name: "Fade (Aether)",           breed: 22,  rarity: 1 },
    149: { name: "Flaunt (Aether)",         breed: 22,  rarity: 2 },
    150: { name: "Bar (Aether)",            breed: 22,  rarity: 2 },
    151: { name: "Boulder (Aether)",        breed: 22,  rarity: 3 },
    152: { name: "Jaguar (Aether)",         breed: 22,  rarity: 2 },
    153: { name: "Jupiter (Aether)",        breed: 22,  rarity: 2 },
    154: { name: "Petals (Aether)",         breed: 22,  rarity: 4 },
    155: { name: "Mosaic (Aether)",         breed: 22,  rarity: 2 },
    156: { name: "Laced (Aether)",          breed: 22,  rarity: 1 },
    157: { name: "Metallic (Aether)",       breed: 22,  rarity: 4 },
    158: { name: "Lionfish (Aether)",       breed: 22,  rarity: 2 },
    159: { name: "Piebald (Aether)",        breed: 22,  rarity: 1 },
    160: { name: "Python (Aether)",         breed: 22,  rarity: 3 },
    161: { name: "Skink (Aether)",          breed: 22,  rarity: 3 },
    162: { name: "Spool (Aether)",          breed: 22,  rarity: 1 },
    163: { name: "Cinder (Aether)",         breed: 22,  rarity: 2 },
    164: { name: "Tide (Aether)",           breed: 22,  rarity: 3 },
    165: { name: "Stitched (Aether)",       breed: 22,  rarity: 3 },
    166: { name: "Twinkle (Aether)",        breed: 22,  rarity: 4 },
    167: { name: "Candy (Aether)",          breed: 22,  rarity: 3 },
    168: { name: "Starmap (Aether)",        breed: 22,  rarity: 4 },
    169: { name: "Clown (Aether)",          breed: 22,  rarity: 1 },
    170: { name: "Harlequin",               breed: 0,   rarity: 4 },
    171: { name: "Boa (Gaoler)",            breed: 17,  rarity: 2 },
    172: { name: "Tide (Gaoler)",           breed: 17,  rarity: 3 },
    173: { name: "Leopard (Gaoler)",        breed: 17,  rarity: 1 },
    174: { name: "Poison (Gaoler)",         breed: 17,  rarity: 3 },
    175: { name: "Ribbon (Gaoler)",         breed: 17,  rarity: 2 },
    176: { name: "Savanna (Gaoler)",        breed: 17,  rarity: 1 },
    177: { name: "Skink (Gaoler)",          breed: 17,  rarity: 3 },
    178: { name: "Slime (Gaoler)",          breed: 17,  rarity: 3 },
    179: { name: "Swirl (Gaoler)",          breed: 17,  rarity: 1 },
    180: { name: "Stitched (Gaoler)",       breed: 17,  rarity: 3 },
    181: { name: "Bar (Banescale)",         breed: 18,  rarity: 2 },
    182: { name: "Crystal (Banescale)",     breed: 18,  rarity: 4 },
    183: { name: "Ribbon (Banescale)",      breed: 18,  rarity: 2 },
    184: { name: "Tide (Banescale)",        breed: 18,  rarity: 3 },
    185: { name: "Pharaoh (Banescale)",     breed: 18,  rarity: 4 },
    186: { name: "Ground (Sandsurge)",      breed: 23,  rarity: 3 },
    187: { name: "Wrought (Sandsurge)",     breed: 23,  rarity: 1 },
    188: { name: "Boulder (Sandsurge)",     breed: 23,  rarity: 3 },
    189: { name: "Boa (Sandsurge)",         breed: 23,  rarity: 2 },
    190: { name: "Cherub (Sandsurge)",      breed: 23,  rarity: 2 },
    191: { name: "Fade (Sandsurge)",        breed: 23,  rarity: 1 },
    192: { name: "Harlequin (Sandsurge)",   breed: 23,  rarity: 4 },
    193: { name: "Flaunt (Sandsurge)",      breed: 23,  rarity: 2 },
    194: { name: "Arapaima (Sandsurge)",    breed: 23,  rarity: 1 },
    195: { name: "Chrysocolla (Sandsurge)", breed: 23,  rarity: 3 },
    196: { name: "Clown (Sandsurge)",       breed: 23,  rarity: 1 },
    197: { name: "Jupiter (Sandsurge)",     breed: 23,  rarity: 2 },
    198: { name: "Jaguar (Sandsurge)",      breed: 23,  rarity: 2 },
    199: { name: "Lionfish (Sandsurge)",    breed: 23,  rarity: 2 },
    200: { name: "Mosaic (Sandsurge)",      breed: 23,  rarity: 2 },
    201: { name: "Piebald (Sandsurge)",     breed: 23,  rarity: 1 },
    202: { name: "Pinstripe (Sandsurge)",   breed: 23,  rarity: 3 },
    203: { name: "Python (Sandsurge)",      breed: 23,  rarity: 3 },
    204: { name: "Savanna (Sandsurge)",     breed: 23,  rarity: 1 },
    205: { name: "Strike (Dusthide)",       breed: 25,  rarity: 2 },
    206: { name: "Rattlesnake (Sandsurge)", breed: 23,  rarity: 2 },
    207: { name: "Wasp (Sandsurge)",        breed: 23,  rarity: 4 },
    208: { name: "Slime (Sandsurge)",       breed: 23,  rarity: 3 },
    209: { name: "Swirl (Sandsurge)",       breed: 23,  rarity: 1 },
    210: { name: "Tapir (Sandsurge)",       breed: 23,  rarity: 1 },
    211: { name: "Tiger (Sandsurge)",       breed: 23,  rarity: 1 },
    212: { name: "Sailfish (Sandsurge)",    breed: 23,  rarity: 3 },
    213: { name: "Cinder",                  breed: 0,   rarity: 2 },
    214: { name: "Cinder (Aberration)",     breed: 20,  rarity: 2 },
    215: { name: "Cinder (Banescale)",      breed: 18,  rarity: 2 },
    216: { name: "Cinder (Gaoler)",         breed: 17,  rarity: 2 },
    217: { name: "Cinder (Sandsurge)",      breed: 23,  rarity: 2 },
    218: { name: "Cinder (Undertide)",      breed: 21,  rarity: 2 },
    219: { name: "Cinder (Veilspun)",       breed: 19,  rarity: 2 },
    220: { name: "Boulder (Aberration)",    breed: 20,  rarity: 3 },
    221: { name: "Cherub (Aberration)",     breed: 20,  rarity: 2 },
    222: { name: "Clown (Aberration)",      breed: 20,  rarity: 1 },
    223: { name: "Fern (Aberration)",       breed: 20,  rarity: 3 },
    224: { name: "Harlequin (Aberration)",  breed: 20,  rarity: 4 },
    225: { name: "Leopard (Aberration)",    breed: 20,  rarity: 1 },
    226: { name: "Pinstripe (Aberration)",  breed: 20,  rarity: 3 },
    227: { name: "Poison (Aberration)",     breed: 20,  rarity: 3 },
    228: { name: "Ripple (Aberration)",     breed: 20,  rarity: 2 },
    229: { name: "Skink (Aberration)",      breed: 20,  rarity: 3 },
    230: { name: "Starmap (Aberration)",    breed: 20,  rarity: 4 },
    231: { name: "Tide (Aberration)",       breed: 20,  rarity: 3 },
    232: { name: "Boa",                     breed: 0,   rarity: 2 },
    233: { name: "Boa (Aberration)",        breed: 20,  rarity: 2 },
    234: { name: "Boa (Aether)",            breed: 22,  rarity: 2 },
    235: { name: "Boa (Banescale)",         breed: 18,  rarity: 2 },
    236: { name: "Boa (Veilspun)",          breed: 19,  rarity: 2 },
    237: { name: "Chrysocolla",             breed: 0,   rarity: 3 },
    238: { name: "Bar (Auraboa)",           breed: 24,  rarity: 2 },
    239: { name: "Boa (Auraboa)",           breed: 24,  rarity: 2 },
    240: { name: "Boulder (Auraboa)",       breed: 24,  rarity: 3 },
    241: { name: "Caterpillar (Auraboa)",   breed: 24,  rarity: 2 },
    242: { name: "Fade (Auraboa)",          breed: 24,  rarity: 1 },
    243: { name: "Falcon (Auraboa)",        breed: 24,  rarity: 1 },
    244: { name: "Fern (Auraboa)",          breed: 24,  rarity: 3 },
    245: { name: "Flaunt (Auraboa)",        breed: 24,  rarity: 2 },
    246: { name: "Giraffe (Auraboa)",       breed: 24,  rarity: 2 },
    247: { name: "Harlequin (Auraboa)",     breed: 24,  rarity: 4 },
    248: { name: "Jaguar (Auraboa)",        breed: 24,  rarity: 2 },
    249: { name: "Laced (Auraboa)",         breed: 24,  rarity: 1 },
    250: { name: "Metallic (Auraboa)",      breed: 24,  rarity: 4 },
    251: { name: "Mochlus (Auraboa)",       breed: 24,  rarity: 2 },
    252: { name: "Mosaic (Auraboa)",        breed: 24,  rarity: 2 },
    253: { name: "Orb (Auraboa)",           breed: 24,  rarity: 3 },
    254: { name: "Piebald (Auraboa)",       breed: 24,  rarity: 1 },
    255: { name: "Python (Auraboa)",        breed: 24,  rarity: 3 },
    256: { name: "Rattlesnake (Auraboa)",   breed: 24,  rarity: 2 },
    257: { name: "Ripple (Auraboa)",        breed: 24,  rarity: 2 },
    258: { name: "Tapir (Auraboa)",         breed: 24,  rarity: 1 },
    259: { name: "Tiger (Auraboa)",         breed: 24,  rarity: 1 },
    260: { name: "Vipera (Auraboa)",        breed: 24,  rarity: 2 },
    261: { name: "Varnish (Auraboa)",       breed: 24,  rarity: 1 },
    262: { name: "Wicker (Auraboa)",        breed: 24,  rarity: 4 },
    263: { name: "Starmap (Auraboa)",       breed: 24,  rarity: 4 },
    264: { name: "Chrysocolla (Undertide)", breed: 21,  rarity: 3 },
    265: { name: "Clown (Undertide)",       breed: 21,  rarity: 1 },
    266: { name: "Fern (Undertide)",        breed: 21,  rarity: 3 },
    267: { name: "Flaunt (Undertide)",      breed: 21,  rarity: 2 },
    268: { name: "Ground (Undertide)",      breed: 21,  rarity: 3 },
    269: { name: "Jaguar (Undertide)",      breed: 21,  rarity: 2 },
    270: { name: "Jupiter (Undertide)",     breed: 21,  rarity: 2 },
    271: { name: "Orb (Undertide)",         breed: 21,  rarity: 3 },
    272: { name: "Piebald (Undertide)",     breed: 21,  rarity: 1 },
    273: { name: "Starmap (Undertide)",     breed: 21,  rarity: 4 },
    274: { name: "Tapir (Undertide)",       breed: 21,  rarity: 1 },
    275: { name: "Tiger (Undertide)",       breed: 21,  rarity: 1 },
    276: { name: "Varnish (Undertide)",     breed: 21,  rarity: 1 },
    277: { name: "Wrought (Undertide)",     breed: 21,  rarity: 1 },
    278: { name: "Metallic (Undertide)",    breed: 21,  rarity: 4 },
    279: { name: "Love (Auraboa)",          breed: 24,  rarity: 3 },
    280: { name: "Harlequin (Undertide)",   breed: 21,  rarity: 4 },
    281: { name: "Petals (Undertide)",      breed: 21,  rarity: 4 },
    282: { name: "Stitched (Undertide)",    breed: 21,  rarity: 3 },
    283: { name: "Orb",                     breed: 0,   rarity: 3 },
    284: { name: "Orb (Sandsurge)",         breed: 23,  rarity: 3 },
    285: { name: "Orb (Veilspun)",          breed: 19,  rarity: 3 },
    286: { name: "Orb (Gaoler)",            breed: 17,  rarity: 3 },
    287: { name: "Orb (Banescale)",         breed: 18,  rarity: 3 },
    288: { name: "Orb (Aether)",            breed: 22,  rarity: 3 },
    289: { name: "Boulder (Gaoler)",        breed: 17,  rarity: 3 },
    290: { name: "Boulder (Veilspun)",      breed: 19,  rarity: 3 },
    291: { name: "Boulder (Banescale)",     breed: 18,  rarity: 3 },
    292: { name: "Bar (Dusthide)",          breed: 25,  rarity: 2 },
    293: { name: "Checkers (Dusthide)",     breed: 25,  rarity: 1 },
    294: { name: "Speckle (Dusthide)",      breed: 25,  rarity: 1 },
    295: { name: "Cinder (Dusthide)",       breed: 25,  rarity: 2 },
    296: { name: "Fade (Dusthide)",         breed: 25,  rarity: 1 },
    297: { name: "Giraffe (Dusthide)",      breed: 25,  rarity: 2 },
    298: { name: "Harlequin (Dusthide)",    breed: 25,  rarity: 4 },
    299: { name: "Ground (Dusthide)",       breed: 25,  rarity: 3 },
    300: { name: "Jupiter (Dusthide)",      breed: 25,  rarity: 2 },
    301: { name: "Falcon (Dusthide)",       breed: 25,  rarity: 1 },
    302: { name: "Laced (Dusthide)",        breed: 25,  rarity: 1 },
    303: { name: "Orb (Dusthide)",          breed: 25,  rarity: 3 },
    304: { name: "Petals (Dusthide)",       breed: 25,  rarity: 4 },
    305: { name: "Piebald (Dusthide)",      breed: 25,  rarity: 1 },
    306: { name: "Arc (Dusthide)",          breed: 25,  rarity: 2 },
    307: { name: "Wrought (Dusthide)",      breed: 25,  rarity: 1 },
    308: { name: "Pinstripe (Dusthide)",    breed: 25,  rarity: 3 },
    309: { name: "Petrified (Dusthide)",    breed: 25,  rarity: 4 },
    310: { name: "Wasp (Dusthide)",         breed: 25,  rarity: 4 },
    311: { name: "Ribbon (Dusthide)",       breed: 25,  rarity: 2 },
    312: { name: "Ripple (Dusthide)",       breed: 25,  rarity: 2 },
    313: { name: "Sailfish (Dusthide)",     breed: 25,  rarity: 3 },
    314: { name: "Savanna (Dusthide)",      breed: 25,  rarity: 1 },
    315: { name: "Skink (Dusthide)",        breed: 25,  rarity: 3 },
    316: { name: "Varnish (Dusthide)",      breed: 25,  rarity: 1 },
    317: { name: "Display (Dusthide)",      breed: 25,  rarity: 3 },
    318: { name: "Petrified",               breed: 0,   rarity: 4 },
    319: { name: "Checkers (Aether)",       breed: 22,  rarity: 1 },
    320: { name: "Chrysocolla (Aether)",    breed: 22,  rarity: 3 },
    321: { name: "Crystal (Aether)",        breed: 22,  rarity: 4 },
    322: { name: "Falcon (Aether)",         breed: 22,  rarity: 1 },
    323: { name: "Fern (Aether)",           breed: 22,  rarity: 3 },
    324: { name: "Giraffe (Aether)",        breed: 22,  rarity: 2 },
    325: { name: "Ground (Aether)",         breed: 22,  rarity: 3 },
    326: { name: "Harlequin (Aether)",      breed: 22,  rarity: 4 },
    327: { name: "Ribbon (Aether)",         breed: 22,  rarity: 2 },
    328: { name: "Ripple (Aether)",         breed: 22,  rarity: 2 },
    329: { name: "Savanna (Aether)",        breed: 22,  rarity: 1 },
    330: { name: "Tapir (Aether)",          breed: 22,  rarity: 1 },
    331: { name: "Tiger (Aether)",          breed: 22,  rarity: 1 },
    332: { name: "Varnish (Aether)",        breed: 22,  rarity: 1 },
    333: { name: "Vipera (Aether)",         breed: 22,  rarity: 2 },
    334: { name: "Wasp (Aether)",           breed: 22,  rarity: 4 },
    335: { name: "Wrought (Aether)",        breed: 22,  rarity: 1 },
    336: { name: "Slime (Aether)",          breed: 22,  rarity: 3 },
    337: { name: "Chorus",                  breed: 0,   rarity: 2 },
    338: { name: "Chorus (Veilspun)",       breed: 19,  rarity: 2 },
    345: { name: "Arapaima (Everlux)",      breed: 26,  rarity: 1 },
    346: { name: "Bar (Everlux)",           breed: 26,  rarity: 2 },
    347: { name: "Caterpillar (Everlux)",   breed: 26,  rarity: 2 },
    348: { name: "Cherub (Everlux)",        breed: 26,  rarity: 2 },
    350: { name: "Chrysocolla (Everlux)",   breed: 26,  rarity: 3 },
    351: { name: "Criculaworm (Everlux)",   breed: 26,  rarity: 2 },
    352: { name: "Crystal (Everlux)",       breed: 26,  rarity: 4 },
    353: { name: "Fade (Everlux)",          breed: 26,  rarity: 1 },
    354: { name: "Flaunt (Everlux)",        breed: 26,  rarity: 2 },
    355: { name: "Jupiter (Everlux)",       breed: 26,  rarity: 2 },
    356: { name: "Laced (Everlux)",         breed: 26,  rarity: 1 },
    357: { name: "Lionfish (Everlux)",      breed: 26,  rarity: 2 },
    358: { name: "Marble (Everlux)",        breed: 26,  rarity: 1 },
    359: { name: "Papillon (Everlux)",      breed: 26,  rarity: 3 },
    360: { name: "Petals (Everlux)",        breed: 26,  rarity: 4 },
    361: { name: "Petrified (Everlux)",     breed: 26,  rarity: 4 },
    362: { name: "Pharaoh (Everlux)",       breed: 26,  rarity: 4 },
    363: { name: "Piebald (Everlux)",       breed: 26,  rarity: 1 },
    365: { name: "Pinstripe (Everlux)",     breed: 26,  rarity: 3 },
    370: { name: "Poison (Everlux)",        breed: 26,  rarity: 3 },
    364: { name: "Python (Everlux)",        breed: 26,  rarity: 3 },
    366: { name: "Ripple (Everlux)",        breed: 26,  rarity: 2 },
    367: { name: "Skink (Everlux)",         breed: 26,  rarity: 3 },
    368: { name: "Slime (Everlux)",         breed: 26,  rarity: 3 },
    369: { name: "Sphinxmoth (Everlux)",    breed: 26,  rarity: 2 },
    371: { name: "Starmap (Everlux)",       breed: 26,  rarity: 4 },
    372: { name: "Strike (Everlux)",        breed: 26,  rarity: 2 },
    377: { name: "Throne (Everlux)",        breed: 26,  rarity: 4 },
    373: { name: "Tide (Everlux)",          breed: 26,  rarity: 3 },
    374: { name: "Tiger (Everlux)",         breed: 26,  rarity: 1 },
    375: { name: "Varnish (Everlux)",       breed: 26,  rarity: 1 },

}

export const secondaryGenes = {
    0: { name: "Basic",                         breed: 0,   rarity: 0 },
    1: { name: "Shimmer",                       breed: 0,   rarity: 4 },
    2: { name: "Stripes",                       breed: 0,   rarity: 1 },
    3: { name: "Eye Spots",                     breed: 0,   rarity: 1 },
    4: { name: "Freckle",                       breed: 0,   rarity: 1 },
    5: { name: "Seraph",                        breed: 0,   rarity: 2 },
    6: { name: "Current",                       breed: 0,   rarity: 2 },
    7: { name: "Daub",                          breed: 0,   rarity: 2 },
    8: { name: "Facet",                         breed: 0,   rarity: 4 },
    9: { name: "Hypnotic",                      breed: 0,   rarity: 2 },
    10: { name: "Paint",                        breed: 0,   rarity: 1 },
    11: { name: "Peregrine",                    breed: 0,   rarity: 1 },
    12: { name: "Toxin",                        breed: 0,   rarity: 3 },
    13: { name: "Butterfly",                    breed: 0,   rarity: 4 },
    14: { name: "Hex",                          breed: 0,   rarity: 2 },
    15: { name: "Saturn",                       breed: 0,   rarity: 2 },
    16: { name: "Spinner",                      breed: 0,   rarity: 3 },
    17: { name: "Alloy",                        breed: 0,   rarity: 4 },
    18: { name: "Safari",                       breed: 0,   rarity: 1 },
    19: { name: "Rosette",                      breed: 0,   rarity: 2 },
    20: { name: "Bee",                          breed: 0,   rarity: 4 },
    21: { name: "Striation",                    breed: 0,   rarity: 1 },
    22: { name: "Trail",                        breed: 0,   rarity: 3 },
    23: { name: "Morph",                        breed: 0,   rarity: 3 },
    24: { name: "Noxtide",                      breed: 0,   rarity: 2 },
    25: { name: "Constellation",                breed: 0,   rarity: 4 },
    26: { name: "Edged",                        breed: 0,   rarity: 1 },
    27: { name: "Hex (Gaoler)",                 breed: 17,  rarity: 2 },
    28: { name: "Bee (Gaoler)",                 breed: 17,  rarity: 4 },
    29: { name: "Streak (Gaoler)",              breed: 17,  rarity: 1 },
    30: { name: "Peregrine (Gaoler)",           breed: 17,  rarity: 1 },
    31: { name: "Paint (Gaoler)",               breed: 17,  rarity: 1 },
    32: { name: "Trail (Gaoler)",               breed: 17,  rarity: 3 },
    33: { name: "Rosette (Gaoler)",             breed: 17,  rarity: 2 },
    34: { name: "Daub (Gaoler)",                breed: 17,  rarity: 2 },
    35: { name: "Striation (Gaoler)",           breed: 17,  rarity: 1 },
    36: { name: "Stripes (Gaoler)",             breed: 17,  rarity: 1 },
    37: { name: "Facet (Gaoler)",               breed: 17,  rarity: 4 },
    38: { name: "Breakup (Gaoler)",             breed: 17,  rarity: 2 },
    39: { name: "Spirit (Gaoler)",              breed: 17,  rarity: 3 },
    40: { name: "Clouded",                      breed: 0,   rarity: 1 },
    41: { name: "Sludge",                       breed: 0,   rarity: 3 },
    42: { name: "Blend",                        breed: 0,   rarity: 1 },
    43: { name: "Seraph (Banescale)",           breed: 18,  rarity: 2 },
    44: { name: "Rosette (Banescale)",          breed: 18,  rarity: 2 },
    45: { name: "Trail (Banescale)",            breed: 18,  rarity: 3 },
    46: { name: "Stripes (Banescale)",          breed: 18,  rarity: 1 },
    47: { name: "Mottle (Banescale)",           breed: 18,  rarity: 1 },
    48: { name: "Edged (Banescale)",            breed: 18,  rarity: 1 },
    49: { name: "Alloy (Banescale)",            breed: 18,  rarity: 4 },
    50: { name: "Safari (Banescale)",           breed: 18,  rarity: 1 },
    51: { name: "Butterfly (Banescale)",        breed: 18,  rarity: 4 },
    52: { name: "Spinner (Banescale)",          breed: 18,  rarity: 3 },
    53: { name: "Toxin (Banescale)",            breed: 18,  rarity: 3 },
    54: { name: "Arrow (Banescale)",            breed: 18,  rarity: 2 },
    55: { name: "Sugarplum (Banescale)",        breed: 18,  rarity: 3 },
    56: { name: "Tear (Banescale)",             breed: 18,  rarity: 2 },
    57: { name: "Marbled",                      breed: 0,   rarity: 1 },
    58: { name: "Breakup",                      breed: 0,   rarity: 2 },
    59: { name: "Patchwork",                    breed: 0,   rarity: 3 },
    60: { name: "Bee (Veilspun)",               breed: 19,  rarity: 4 },
    61: { name: "Blend (Veilspun)",             breed: 19,  rarity: 1 },
    62: { name: "Edged (Veilspun)",             breed: 19,  rarity: 1 },
    63: { name: "Striation (Veilspun)",         breed: 19,  rarity: 1 },
    64: { name: "Hypnotic (Veilspun)",          breed: 19,  rarity: 2 },
    65: { name: "Saturn (Veilspun)",            breed: 19,  rarity: 2 },
    66: { name: "Constellation (Veilspun)",     breed: 19,  rarity: 4 },
    67: { name: "Patchwork (Veilspun)",         breed: 19,  rarity: 3 },
    68: { name: "Spinner (Veilspun)",           breed: 19,  rarity: 3 },
    69: { name: "Vivid (Veilspun)",             breed: 19,  rarity: 2 },
    70: { name: "Loop (Veilspun)",              breed: 19,  rarity: 2 },
    71: { name: "Web (Veilspun)",               breed: 19,  rarity: 2 },
    72: { name: "Hawkmoth (Veilspun)",          breed: 19,  rarity: 2 },
    73: { name: "Edged (Gaoler)",               breed: 17,  rarity: 1 },
    74: { name: "Striation (Banescale)",        breed: 18,  rarity: 1 },
    75: { name: "Eye Spots (Banescale)",        breed: 18,  rarity: 1 },
    76: { name: "Eye Spots (Veilspun)",         breed: 19,  rarity: 1 },
    77: { name: "Eye Spots (Gaoler)",           breed: 17,  rarity: 1 },
    78: { name: "Current (Gaoler)",             breed: 17,  rarity: 2 },
    79: { name: "Current (Banescale)",          breed: 18,  rarity: 2 },
    80: { name: "Peregrine (Banescale)",        breed: 18,  rarity: 1 },
    81: { name: "Hex (Banescale)",              breed: 18,  rarity: 2 },
    82: { name: "Flair",                        breed: 0,   rarity: 2 },
    83: { name: "Hex (Veilspun)",               breed: 19,  rarity: 1 },
    84: { name: "Eel",                          breed: 0,   rarity: 2 },
    85: { name: "Blend (Banescale)",            breed: 18,  rarity: 1 },
    86: { name: "Blend (Gaoler)",               breed: 17,  rarity: 1 },
    87: { name: "Sarcophagus",                  breed: 0,   rarity: 4 },
    88: { name: "Fissure",                      breed: 0,   rarity: 3 },
    89: { name: "Daub (Aberration)",            breed: 20,  rarity: 2 },
    90: { name: "Facet (Aberration)",           breed: 20,  rarity: 4 },
    91: { name: "Blend (Aberration)",           breed: 20,  rarity: 1 },
    92: { name: "Peregrine (Aberration)",       breed: 20,  rarity: 1 },
    93: { name: "Spade (Aberration)",           breed: 20,  rarity: 3 },
    94: { name: "Hex (Aberration)",             breed: 20,  rarity: 2 },
    95: { name: "Striation (Aberration)",       breed: 20,  rarity: 1 },
    96: { name: "Hypnotic (Aberration)",        breed: 20,  rarity: 2 },
    97: { name: "Fissure (Aberration)",         breed: 20,  rarity: 3 },
    98: { name: "Freckle (Aberration)",         breed: 20,  rarity: 1 },
    99: { name: "Rosette (Aberration)",         breed: 20,  rarity: 2 },
    100: { name: "Noxtide (Aberration)",        breed: 20,  rarity: 2 },
    101: { name: "Sarcophagus (Aberration)",    breed: 20,  rarity: 4 },
    102: { name: "Weaver (Aberration)",         breed: 20,  rarity: 3 },
    103: { name: "Marbled (Aberration)",        breed: 20,  rarity: 1 },
    104: { name: "Safari (Aberration)",         breed: 20,  rarity: 1 },
    105: { name: "Eel (Aberration)",            breed: 20,  rarity: 2 },
    106: { name: "Sludge (Aberration)",         breed: 20,  rarity: 3 },
    107: { name: "Patchwork (Aberration)",      breed: 20,  rarity: 3 },
    108: { name: "Bee (Aberration)",            breed: 20,  rarity: 4 },
    109: { name: "Clouded (Banescale)",         breed: 18,  rarity: 1 },
    110: { name: "Myrid",                       breed: 0,   rarity: 3 },
    111: { name: "Flair (Gaoler)",              breed: 17,  rarity: 2 },
    112: { name: "Flair (Aberration)",          breed: 20,  rarity: 2 },
    113: { name: "Foam",                        breed: 0,   rarity: 3 },
    114: { name: "Foam (Undertide)",            breed: 21,  rarity: 3 },
    115: { name: "Blend (Undertide)",           breed: 21,  rarity: 1 },
    116: { name: "Pack (Undertide)",            breed: 21,  rarity: 2 },
    117: { name: "Daub (Undertide)",            breed: 21,  rarity: 2 },
    118: { name: "Facet (Undertide)",           breed: 21,  rarity: 4 },
    119: { name: "Seraph (Undertide)",          breed: 21,  rarity: 2 },
    120: { name: "Sarcophagus (Undertide)",     breed: 21,  rarity: 4 },
    121: { name: "Trail (Undertide)",           breed: 21,  rarity: 3 },
    122: { name: "Peregrine (Undertide)",       breed: 21,  rarity: 1 },
    123: { name: "Hex (Undertide)",             breed: 21,  rarity: 2 },
    124: { name: "Eel (Undertide)",             breed: 21,  rarity: 2 },
    125: { name: "Bee (Undertide)",             breed: 21,  rarity: 4 },
    126: { name: "Noxtide (Undertide)",         breed: 21,  rarity: 2 },
    127: { name: "Chess (Undertide)",           breed: 21,  rarity: 1 },
    128: { name: "Saddle (Undertide)",          breed: 21,  rarity: 2 },
    129: { name: "Safari (Undertide)",          breed: 21,  rarity: 1 },
    130: { name: "Current (Undertide)",         breed: 21,  rarity: 2 },
    131: { name: "Toxin (Undertide)",           breed: 21,  rarity: 3 },
    132: { name: "Freckle (Undertide)",         breed: 21,  rarity: 1 },
    133: { name: "Rings (Undertide)",           breed: 21,  rarity: 3 },
    134: { name: "Marbled (Undertide)",         breed: 21,  rarity: 1 },
    135: { name: "Myrid (Undertide)",           breed: 21,  rarity: 3 },
    136: { name: "Paisley",                     breed: 0,   rarity: 3 },
    137: { name: "Paisley (Banescale)",         breed: 18,  rarity: 3 },
    138: { name: "Paisley (Veilspun)",          breed: 19,  rarity: 3 },
    139: { name: "Peregrine (Veilspun)",        breed: 19,  rarity: 1 },
    140: { name: "Toxin (Veilspun)",            breed: 19,  rarity: 3 },
    141: { name: "Sludge (Veilspun)",           breed: 19,  rarity: 3 },
    142: { name: "Clouded (Veilspun)",          breed: 19,  rarity: 1 },
    143: { name: "Freckle (Veilspun)",          breed: 19,  rarity: 1 },
    144: { name: "Butterfly (Veilspun)",        breed: 19,  rarity: 4 },
    145: { name: "Daub (Veilspun)",             breed: 19,  rarity: 2 },
    146: { name: "Facet (Veilspun)",            breed: 19,  rarity: 4 },
    147: { name: "Freckle (Banescale)",         breed: 18,  rarity: 1 },
    148: { name: "Blend (Aether)",              breed: 22,  rarity: 1 },
    149: { name: "Flair (Aether)",              breed: 22,  rarity: 2 },
    150: { name: "Daub (Aether)",               breed: 22,  rarity: 2 },
    151: { name: "Myrid (Aether)",              breed: 22,  rarity: 3 },
    152: { name: "Rosette (Aether)",            breed: 22,  rarity: 2 },
    153: { name: "Saturn (Aether)",             breed: 22,  rarity: 2 },
    154: { name: "Butterfly (Aether)",          breed: 22,  rarity: 4 },
    155: { name: "Breakup (Aether)",            breed: 22,  rarity: 2 },
    156: { name: "Edged (Aether)",              breed: 22,  rarity: 1 },
    157: { name: "Alloy (Aether)",              breed: 22,  rarity: 4 },
    158: { name: "Noxtide (Aether)",            breed: 22,  rarity: 2 },
    159: { name: "Paint (Aether)",              breed: 22,  rarity: 1 },
    160: { name: "Morph (Aether)",              breed: 22,  rarity: 3 },
    161: { name: "Spinner (Aether)",            breed: 22,  rarity: 3 },
    162: { name: "Thread (Aether)",             breed: 22,  rarity: 1 },
    163: { name: "Blaze (Aether)",              breed: 22,  rarity: 2 },
    164: { name: "Foam (Aether)",               breed: 22,  rarity: 3 },
    165: { name: "Patchwork (Aether)",          breed: 22,  rarity: 3 },
    166: { name: "Flicker (Aether)",            breed: 22,  rarity: 4 },
    167: { name: "Icing (Aether)",              breed: 22,  rarity: 3 },
    168: { name: "Constellation (Aether)",      breed: 22,  rarity: 4 },
    169: { name: "Eye Spots (Aether)",          breed: 22,  rarity: 1 },
    170: { name: "Jester",                      breed: 0,   rarity: 4 },
    171: { name: "Saddle (Gaoler)",             breed: 17,  rarity: 2 },
    172: { name: "Foam (Gaoler)",               breed: 17,  rarity: 3 },
    173: { name: "Toxin (Gaoler)",              breed: 17,  rarity: 3 },
    174: { name: "Eel (Gaoler)",                breed: 17,  rarity: 2 },
    175: { name: "Safari (Gaoler)",             breed: 17,  rarity: 1 },
    176: { name: "Spinner (Gaoler)",            breed: 17,  rarity: 3 },
    177: { name: "Sludge (Gaoler)",             breed: 17,  rarity: 3 },
    178: { name: "Marbled (Gaoler)",            breed: 17,  rarity: 1 },
    179: { name: "Patchwork (Gaoler)",          breed: 17,  rarity: 3 },
    180: { name: "Clouded (Gaoler)",            breed: 17,  rarity: 1 },
    181: { name: "Daub (Banescale)",            breed: 18,  rarity: 2 },
    182: { name: "Facet (Banescale)",           breed: 18,  rarity: 4 },
    183: { name: "Eel (Banescale)",             breed: 18,  rarity: 2 },
    184: { name: "Foam (Banescale)",            breed: 18,  rarity: 3 },
    185: { name: "Sarcophagus (Banescale)",     breed: 18,  rarity: 4 },
    186: { name: "Fissure (Sandsurge)",         breed: 23,  rarity: 3 },
    187: { name: "Spire (Sandsurge)",           breed: 23,  rarity: 1 },
    188: { name: "Myrid (Sandsurge)",           breed: 23,  rarity: 3 },
    189: { name: "Saddle (Sandsurge)",          breed: 23,  rarity: 2 },
    190: { name: "Seraph (Sandsurge)",          breed: 23,  rarity: 2 },
    191: { name: "Blend (Sandsurge)",           breed: 23,  rarity: 1 },
    192: { name: "Jester (Sandsurge)",          breed: 23,  rarity: 4 },
    193: { name: "Flair (Sandsurge)",           breed: 23,  rarity: 2 },
    194: { name: "Arowana (Sandsurge)",         breed: 23,  rarity: 1 },
    195: { name: "Malachite (Sandsurge)",       breed: 23,  rarity: 3 },
    196: { name: "Eye Spots (Sandsurge)",       breed: 23,  rarity: 1 },
    197: { name: "Saturn (Sandsurge)",          breed: 23,  rarity: 2 },
    198: { name: "Rosette (Sandsurge)",         breed: 23,  rarity: 2 },
    199: { name: "Noxtide (Sandsurge)",         breed: 23,  rarity: 2 },
    200: { name: "Breakup (Sandsurge)",         breed: 23,  rarity: 2 },
    201: { name: "Paint (Sandsurge)",           breed: 23,  rarity: 1 },
    202: { name: "Trail (Sandsurge)",           breed: 23,  rarity: 3 },
    203: { name: "Morph (Sandsurge)",           breed: 23,  rarity: 3 },
    204: { name: "Safari (Sandsurge)",          breed: 23,  rarity: 1 },
    205: { name: "Coil (Dusthide)",             breed: 25,  rarity: 2 },
    206: { name: "Diamondback (Sandsurge)",     breed: 23,  rarity: 2 },
    207: { name: "Bee (Sandsurge)",             breed: 23,  rarity: 4 },
    208: { name: "Sludge (Sandsurge)",          breed: 23,  rarity: 3 },
    209: { name: "Marbled (Sandsurge)",         breed: 23,  rarity: 1 },
    210: { name: "Striation (Sandsurge)",       breed: 23,  rarity: 1 },
    211: { name: "Stripes (Sandsurge)",         breed: 23,  rarity: 1 },
    212: { name: "Marlin (Sandsurge)",          breed: 23,  rarity: 3 },
    213: { name: "Blaze",                       breed: 0,   rarity: 2 },
    214: { name: "Blaze (Aberration)",          breed: 20,  rarity: 2 },
    215: { name: "Blaze (Banescale)",           breed: 18,  rarity: 2 },
    216: { name: "Blaze (Gaoler)",              breed: 17,  rarity: 2 },
    217: { name: "Blaze (Sandsurge)",           breed: 23,  rarity: 2 },
    218: { name: "Blaze (Undertide)",           breed: 21,  rarity: 2 },
    219: { name: "Blaze (Veilspun)",            breed: 19,  rarity: 2 },
    220: { name: "Clouded (Aberration)",        breed: 20,  rarity: 3 },
    221: { name: "Constellation (Aberration)",  breed: 20,  rarity: 2 },
    222: { name: "Current (Aberration)",        breed: 20,  rarity: 1 },
    223: { name: "Eye Spots (Aberration)",      breed: 20,  rarity: 3 },
    224: { name: "Foam (Aberration)",           breed: 20,  rarity: 3 },
    225: { name: "Jester (Aberration)",         breed: 20,  rarity: 4 },
    226: { name: "Myrid (Aberration)",          breed: 20,  rarity: 3 },
    227: { name: "Paisley (Aberration)",        breed: 20,  rarity: 3 },
    228: { name: "Seraph (Aberration)",         breed: 20,  rarity: 2 },
    229: { name: "Spinner (Aberration)",        breed: 20,  rarity: 3 },
    230: { name: "Toxin (Aberration)",          breed: 20,  rarity: 3 },
    231: { name: "Trail (Aberration)",          breed: 20,  rarity: 3 },
    232: { name: "Saddle",                      breed: 0,   rarity: 2 },
    233: { name: "Saddle (Aberration)",         breed: 20,  rarity: 2 },
    234: { name: "Saddle (Aether)",             breed: 22,  rarity: 2 },
    235: { name: "Saddle (Banescale)",          breed: 18,  rarity: 2 },
    236: { name: "Saddle (Veilspun)",           breed: 19,  rarity: 2 },
    237: { name: "Malachite",                   breed: 0,   rarity: 3 },
    238: { name: "Daub (Auraboa)",              breed: 24,  rarity: 2 },
    239: { name: "Saddle (Auraboa)",            breed: 24,  rarity: 2 },
    240: { name: "Myrid (Auraboa)",             breed: 24,  rarity: 3 },
    241: { name: "Larvae (Auraboa)",            breed: 24,  rarity: 2 },
    242: { name: "Blend (Auraboa)",             breed: 24,  rarity: 1 },
    243: { name: "Peregrine (Auraboa)",         breed: 24,  rarity: 1 },
    244: { name: "Paisley (Auraboa)",           breed: 24,  rarity: 3 },
    245: { name: "Flair (Auraboa)",             breed: 24,  rarity: 2 },
    246: { name: "Hex (Auraboa)",               breed: 24,  rarity: 2 },
    247: { name: "Jester (Auraboa)",            breed: 24,  rarity: 4 },
    248: { name: "Rosette (Auraboa)",           breed: 24,  rarity: 2 },
    249: { name: "Edged (Auraboa)",             breed: 24,  rarity: 1 },
    250: { name: "Alloy (Auraboa)",             breed: 24,  rarity: 4 },
    251: { name: "Riopa (Auraboa)",             breed: 24,  rarity: 2 },
    252: { name: "Breakup (Auraboa)",           breed: 24,  rarity: 2 },
    253: { name: "Weaver (Auraboa)",            breed: 24,  rarity: 3 },
    254: { name: "Paint (Auraboa)",             breed: 24,  rarity: 1 },
    255: { name: "Morph (Auraboa)",             breed: 24,  rarity: 3 },
    256: { name: "Diamondback (Auraboa)",       breed: 24,  rarity: 2 },
    257: { name: "Current (Auraboa)",           breed: 24,  rarity: 2 },
    258: { name: "Striation (Auraboa)",         breed: 24,  rarity: 1 },
    259: { name: "Stripes (Auraboa)",           breed: 24,  rarity: 1 },
    260: { name: "Hypnotic (Auraboa)",          breed: 24,  rarity: 2 },
    261: { name: "Lacquer (Auraboa)",           breed: 24,  rarity: 1 },
    262: { name: "Woven (Auraboa)",             breed: 24,  rarity: 4 },
    263: { name: "Constellation (Auraboa)",     breed: 24,  rarity: 4 },
    264: { name: "Malachite (Undertide)",       breed: 21,  rarity: 3 },
    265: { name: "Eye Spots (Undertide)",       breed: 21,  rarity: 1 },
    266: { name: "Paisley (Undertide)",         breed: 21,  rarity: 3 },
    267: { name: "Flair (Undertide)",           breed: 21,  rarity: 2 },
    268: { name: "Fissure (Undertide)",         breed: 21,  rarity: 3 },
    269: { name: "Rosette (Undertide)",         breed: 21,  rarity: 2 },
    270: { name: "Saturn (Undertide)",          breed: 21,  rarity: 2 },
    271: { name: "Weaver (Undertide)",          breed: 21,  rarity: 3 },
    272: { name: "Paint (Undertide)",           breed: 21,  rarity: 1 },
    273: { name: "Constellation (Undertide)",   breed: 21,  rarity: 4 },
    274: { name: "Striation (Undertide)",       breed: 21,  rarity: 1 },
    275: { name: "Stripes (Undertide)",         breed: 21,  rarity: 1 },
    276: { name: "Lacquer (Undertide)",         breed: 21,  rarity: 1 },
    277: { name: "Spire (Undertide)",           breed: 21,  rarity: 1 },
    278: { name: "Alloy (Undertide)",           breed: 21,  rarity: 4 },
    279: { name: "Affection (Auraboa)",         breed: 24,  rarity: 3 },
    280: { name: "Patchwork (Undertide)",       breed: 21,  rarity: 3 },
    281: { name: "Butterfly (Undertide)",       breed: 21,  rarity: 4 },
    282: { name: "Jester (Undertide)",          breed: 21,  rarity: 4 },
    283: { name: "Weaver",                      breed: 0,   rarity: 3 },
    284: { name: "Weaver (Aether)",             breed: 22,  rarity: 3 },
    285: { name: "Weaver (Banescale)",          breed: 18,  rarity: 3 },
    286: { name: "Weaver (Gaoler)",             breed: 17,  rarity: 3 },
    287: { name: "Weaver (Sandsurge)",          breed: 23,  rarity: 3 },
    288: { name: "Weaver (Veilspun)",           breed: 19,  rarity: 3 },
    289: { name: "Myrid (Banescale)",           breed: 18,  rarity: 3 },
    290: { name: "Myrid (Gaoler)",              breed: 17,  rarity: 3 },
    291: { name: "Myrid (Veilspun)",            breed: 19,  rarity: 3 },
    292: { name: "Daub (Dusthide)",             breed: 25,  rarity: 2 },
    293: { name: "Chess (Dusthide)",            breed: 25,  rarity: 1 },
    294: { name: "Freckle (Dusthide)",          breed: 25,  rarity: 1 },
    295: { name: "Blaze (Dusthide)",            breed: 25,  rarity: 2 },
    296: { name: "Blend (Dusthide)",            breed: 25,  rarity: 1 },
    297: { name: "Hex (Dusthide)",              breed: 25,  rarity: 2 },
    298: { name: "Fissure (Dusthide)",          breed: 25,  rarity: 3 },
    299: { name: "Jester (Dusthide)",           breed: 25,  rarity: 4 },
    300: { name: "Saturn (Dusthide)",           breed: 25,  rarity: 2 },
    301: { name: "Peregrine (Dusthide)",        breed: 25,  rarity: 1 },
    302: { name: "Edged (Dusthide)",            breed: 25,  rarity: 1 },
    303: { name: "Weaver (Dusthide)",           breed: 25,  rarity: 3 },
    304: { name: "Butterfly (Dusthide)",        breed: 25,  rarity: 4 },
    305: { name: "Paint (Dusthide)",            breed: 25,  rarity: 1 },
    306: { name: "Loop (Dusthide)",             breed: 25,  rarity: 2 },
    307: { name: "Spire (Dusthide)",            breed: 25,  rarity: 1 },
    308: { name: "Trail (Dusthide)",            breed: 25,  rarity: 3 },
    309: { name: "Lode (Dusthide)",             breed: 25,  rarity: 4 },
    310: { name: "Bee (Dusthide)",              breed: 25,  rarity: 4 },
    311: { name: "Eel (Dusthide)",              breed: 25,  rarity: 2 },
    312: { name: "Current (Dusthide)",          breed: 25,  rarity: 2 },
    313: { name: "Marlin (Dusthide)",           breed: 25,  rarity: 3 },
    314: { name: "Safari (Dusthide)",           breed: 25,  rarity: 1 },
    315: { name: "Spinner (Dusthide)",          breed: 25,  rarity: 3 },
    316: { name: "Lacquer (Dusthide)",          breed: 25,  rarity: 1 },
    317: { name: "Parade (Dusthide)",           breed: 25,  rarity: 3 },
    318: { name: "Lode",                        breed: 0,   rarity: 4 },
    319: { name: "Bee (Aether)",                breed: 22,  rarity: 4 },
    320: { name: "Chess (Aether)",              breed: 22,  rarity: 1 },
    321: { name: "Current (Aether)",            breed: 22,  rarity: 2 },
    322: { name: "Eel (Aether)",                breed: 22,  rarity: 2 },
    323: { name: "Fern (Aether)",               breed: 22,  rarity: 3 },
    324: { name: "Fissure (Aether)",            breed: 22,  rarity: 3 },
    325: { name: "Hex (Aether)",                breed: 22,  rarity: 2 },
    326: { name: "Hypnotic (Aether)",           breed: 22,  rarity: 2 },
    327: { name: "Jester (Aether)",             breed: 22,  rarity: 4 },
    328: { name: "Lacquer (Aether)",            breed: 22,  rarity: 1 },
    329: { name: "Malachite (Aether)",          breed: 22,  rarity: 3 },
    330: { name: "Paisley (Aether)",            breed: 22,  rarity: 3 },
    331: { name: "Peregrine (Aether)",          breed: 22,  rarity: 1 },
    332: { name: "Safari (Aether)",             breed: 22,  rarity: 1 },
    333: { name: "Striation (Aether)",          breed: 22,  rarity: 1 },
    334: { name: "Stripes (Aether)",            breed: 22,  rarity: 1 },
    335: { name: "Spire (Aether)",              breed: 22,  rarity: 1 },
    336: { name: "Sludge (Aether)",             breed: 22,  rarity: 3 },
    337: { name: "Choir",                       breed: 0,   rarity: 2 },
    338: { name: "Choir (Veilspun)",            breed: 19,  rarity: 2 },
    345: { name: "Arowana (Everlux)",           breed: 26,  rarity: 1 },
    347: { name: "Blend (Everlux)",             breed: 26,  rarity: 1 },
    359: { name: "Butterfly (Everlux)",         breed: 26,  rarity: 4 },
    371: { name: "Coil (Everlux)",              breed: 26,  rarity: 2 },
    372: { name: "Constellation (Everlux)",     breed: 26,  rarity: 4 },
    378: { name: "Crown (Everlux)",             breed: 26,  rarity: 4 },
    366: { name: "Current (Everlux)",           breed: 26,  rarity: 1 },
    348: { name: "Daub (Everlux)",              breed: 26,  rarity: 2 },
    355: { name: "Edged (Everlux)",             breed: 26,  rarity: 1 },
    349: { name: "Facet (Everlux)",             breed: 26,  rarity: 4 },
    350: { name: "Flair (Everlux)",             breed: 26,  rarity: 2 },
    373: { name: "Foam (Everlux)",              breed: 26,  rarity: 3 },
    369: { name: "Hawkmoth (Everlux)",          breed: 26,  rarity: 2 },
    376: { name: "Lacquer (Everlux)",           breed: 26,  rarity: 1 },
    351: { name: "Larvae (Everlux)",            breed: 26,  rarity: 2 },
    360: { name: "Lode (Everlux)",              breed: 26,  rarity: 4 },
    352: { name: "Malachite (Everlux)",         breed: 26,  rarity: 3 },
    362: { name: "Morph (Everlux)",             breed: 26,  rarity: 3 },
    356: { name: "Mottle (Everlux)",            breed: 26,  rarity: 1 },
    357: { name: "Noxtide (Everlux)",           breed: 26,  rarity: 2 },
    361: { name: "Paint (Everlux)",             breed: 26,  rarity: 1 },
    363: { name: "Sarcophagus (Everlux)",       breed: 26,  rarity: 4 },
    358: { name: "Saturn (Everlux)",            breed: 26,  rarity: 2 },
    353: { name: "Seraph (Everlux)",            breed: 26,  rarity: 2 },
    354: { name: "Silkspot (Everlux)",          breed: 26,  rarity: 2 },
    370: { name: "Sludge (Everlux)",            breed: 26,  rarity: 3 },
    367: { name: "Spinner (Everlux)",           breed: 26,  rarity: 3 },
    374: { name: "Stripes (Everlux)",           breed: 26,  rarity: 1 },
    364: { name: "Swallowtail (Everlux)",       breed: 26,  rarity: 3 },
    375: { name: "Toxin (Everlux)",             breed: 26,  rarity: 3 },
    368: { name: "Trail (Everlux)",             breed: 26,  rarity: 3 },

}

export const tertiaryGenes = {
    0: { name: "Basic",                         breed: 0,   rarity: 0 },
    1: { name: "Circuit",                       breed: 0,   rarity: 4 },
    2: { name: "Veined (Gaoler)",               breed: 17,  rarity: 3 },
    3: { name: "Fans (Gaoler)",                 breed: 17,  rarity: 4 },
    4: { name: "Gembond",                       breed: 0,   rarity: 2 },
    5: { name: "Underbelly",                    breed: 0,   rarity: 1 },
    6: { name: "Crackle",                       breed: 0,   rarity: 2 },
    7: { name: "Smoke",                         breed: 0,   rarity: 2 },
    8: { name: "Spines",                        breed: 0,   rarity: 2 },
    9: { name: "Okapi",                         breed: 0,   rarity: 2 },
    10: { name: "Glimmer",                      breed: 0,   rarity: 4 },
    11: { name: "Thylacine",                    breed: 0,   rarity: 1 },
    12: { name: "Stained",                      breed: 0,   rarity: 4 },
    13: { name: "Contour",                      breed: 0,   rarity: 1 },
    14: { name: "Runes",                        breed: 0,   rarity: 2 },
    15: { name: "Scales",                       breed: 0,   rarity: 3 },
    16: { name: "Lace",                         breed: 0,   rarity: 2 },
    17: { name: "Opal",                         breed: 0,   rarity: 4 },
    18: { name: "Capsule",                      breed: 0,   rarity: 3 },
    19: { name: "Smirch",                       breed: 0,   rarity: 3 },
    20: { name: "Ghost",                        breed: 0,   rarity: 2 },
    21: { name: "Filigree",                     breed: 0,   rarity: 4 },
    22: { name: "Firefly",                      breed: 0,   rarity: 3 },
    23: { name: "Ringlets",                     breed: 0,   rarity: 2 },
    24: { name: "Peacock",                      breed: 0,   rarity: 1 },
    25: { name: "Ghost (Gaoler)",               breed: 17,  rarity: 2 },
    26: { name: "Shardflank (Gaoler)",          breed: 17,  rarity: 1 },
    27: { name: "Gnarlhorns (Gaoler)",          breed: 17,  rarity: 4 },
    28: { name: "Smoke (Gaoler)",               breed: 17,  rarity: 2 },
    29: { name: "Thylacine (Gaoler)",           breed: 17,  rarity: 1 },
    30: { name: "Ringlets (Gaoler)",            breed: 17,  rarity: 2 },
    31: { name: "Underbelly (Gaoler)",          breed: 17,  rarity: 1 },
    32: { name: "Runes (Gaoler)",               breed: 17,  rarity: 2 },
    33: { name: "Scorpion (Gaoler)",            breed: 17,  rarity: 3 },
    34: { name: "Wintercoat (Gaoler)",          breed: 17,  rarity: 2 },
    35: { name: "Weathered (Gaoler)",           breed: 17,  rarity: 3 },
    36: { name: "Blossom (Gaoler)",             breed: 17,  rarity: 3 },
    37: { name: "Opal (Gaoler)",                breed: 17,  rarity: 4 },
    38: { name: "Veined",                       breed: 0,   rarity: 3 },
    39: { name: "Trimmings (Banescale)",        breed: 18,  rarity: 1 },
    40: { name: "Ringlets (Banescale)",         breed: 18,  rarity: 2 },
    41: { name: "Fans (Banescale)",             breed: 18,  rarity: 4 },
    42: { name: "Squiggle (Banescale)",         breed: 18,  rarity: 3 },
    43: { name: "Filigree (Banescale)",         breed: 18,  rarity: 4 },
    44: { name: "Lace (Banescale)",             breed: 18,  rarity: 2 },
    45: { name: "Skeletal (Banescale)",         breed: 18,  rarity: 3 },
    46: { name: "Contour (Banescale)",          breed: 18,  rarity: 1 },
    47: { name: "Ghost (Banescale)",            breed: 18,  rarity: 2 },
    48: { name: "Wraith (Banescale)",           breed: 18,  rarity: 4 },
    49: { name: "Porcupine (Banescale)",        breed: 18,  rarity: 3 },
    50: { name: "Crackle (Banescale)",          breed: 18,  rarity: 2 },
    51: { name: "Plumage (Banescale)",          breed: 18,  rarity: 4 },
    52: { name: "Underbelly (Banescale)",       breed: 18,  rarity: 1 },
    53: { name: "Keel",                         breed: 0,   rarity: 3 },
    54: { name: "Glowtail",                     breed: 0,   rarity: 4 },
    55: { name: "Braids (Gaoler)",              breed: 17,  rarity: 2 },
    56: { name: "Capsule (Veilspun)",           breed: 19,  rarity: 3 },
    57: { name: "Runes (Veilspun)",             breed: 19,  rarity: 2 },
    58: { name: "Crackle (Veilspun)",           breed: 19,  rarity: 2 },
    59: { name: "Okapi (Veilspun)",             breed: 19,  rarity: 2 },
    60: { name: "Peacock (Veilspun)",           breed: 19,  rarity: 1 },
    61: { name: "Firefly (Veilspun)",           breed: 19,  rarity: 3 },
    62: { name: "Opal (Veilspun)",              breed: 19,  rarity: 4 },
    63: { name: "Branches (Veilspun)",          breed: 19,  rarity: 3 },
    64: { name: "Flecks (Veilspun)",            breed: 19,  rarity: 3 },
    65: { name: "Beetle (Veilspun)",            breed: 19,  rarity: 3 },
    66: { name: "Diaphanous (Veilspun)",        breed: 19,  rarity: 4 },
    67: { name: "Mop (Veilspun)",               breed: 19,  rarity: 4 },
    68: { name: "Thorns (Veilspun)",            breed: 19,  rarity: 2 },
    69: { name: "Stained (Banescale)",          breed: 18,  rarity: 4 },
    70: { name: "Underbelly (Veilspun)",        breed: 19,  rarity: 1 },
    71: { name: "Stained (Gaoler)",             breed: 17,  rarity: 4 },
    72: { name: "Stained (Veilspun)",           breed: 19,  rarity: 4 },
    73: { name: "Koi",                          breed: 0,   rarity: 4 },
    74: { name: "Capsule (Banescale)",          breed: 18,  rarity: 3 },
    75: { name: "Capsule (Gaoler)",             breed: 17,  rarity: 3 },
    76: { name: "Gliders (Banescale)",          breed: 18,  rarity: 3 },
    77: { name: "Pinions (Gaoler)",             breed: 17,  rarity: 4 },
    78: { name: "Angler (Veilspun)",            breed: 19,  rarity: 3 },
    79: { name: "Polkadot (Aberration)",        breed: 20,  rarity: 3 },
    80: { name: "Kumo (Aberration)",            breed: 20,  rarity: 1 },
    81: { name: "Mucous (Aberration)",          breed: 20,  rarity: 3 },
    82: { name: "Polypore (Aberration)",        breed: 20,  rarity: 3 },
    83: { name: "Capsule (Aberration)",         breed: 20,  rarity: 3 },
    84: { name: "Fangs (Aberration)",           breed: 20,  rarity: 2 },
    85: { name: "Firefly (Aberration)",         breed: 20,  rarity: 3 },
    86: { name: "Frills (Aberration)",          breed: 20,  rarity: 4 },
    87: { name: "Jewels (Aberration)",          breed: 20,  rarity: 4 },
    88: { name: "Ghost (Aberration)",           breed: 20,  rarity: 2 },
    89: { name: "Glowtail (Aberration)",        breed: 20,  rarity: 4 },
    90: { name: "Peacock (Aberration)",         breed: 20,  rarity: 1 },
    91: { name: "Veined (Aberration)",          breed: 20,  rarity: 3 },
    92: { name: "Scales (Aberration)",          breed: 20,  rarity: 3 },
    93: { name: "Thylacine (Aberration)",       breed: 20,  rarity: 1 },
    94: { name: "Glimmer (Aberration)",         breed: 20,  rarity: 4 },
    95: { name: "Glimmer (Banescale)",          breed: 18,  rarity: 4 },
    96: { name: "Sparkle (Aberration)",         breed: 20,  rarity: 2 },
    97: { name: "Sparkle",                      breed: 0,   rarity: 2 },
    98: { name: "Sparkle (Banescale)",          breed: 18,  rarity: 2 },
    99: { name: "Sparkle (Gaoler)",             breed: 17,  rarity: 2 },
    100: { name: "Sparkle (Veilspun)",          breed: 19,  rarity: 2 },
    101: { name: "Glimmer (Gaoler)",            breed: 17,  rarity: 4 },
    102: { name: "Glimmer (Veilspun)",          breed: 19,  rarity: 4 },
    103: { name: "Flecks",                      breed: 0,   rarity: 3 },
    104: { name: "Flecks (Aberration)",         breed: 20,  rarity: 3 },
    105: { name: "Soap",                        breed: 0,   rarity: 4 },
    106: { name: "Peacock (Banescale)",         breed: 18,  rarity: 1 },
    107: { name: "Points",                      breed: 0,   rarity: 1 },
    108: { name: "Koi (Veilspun)",              breed: 19,  rarity: 4 },
    109: { name: "Underbelly (Undertide)",      breed: 21,  rarity: 1 },
    110: { name: "Stained (Undertide)",         breed: 21,  rarity: 4 },
    111: { name: "Capsule (Undertide)",         breed: 21,  rarity: 3 },
    112: { name: "Flecks (Undertide)",          breed: 21,  rarity: 3 },
    113: { name: "Veined (Undertide)",          breed: 21,  rarity: 3 },
    114: { name: "Runes (Undertide)",           breed: 21,  rarity: 2 },
    115: { name: "Crackle (Undertide)",         breed: 21,  rarity: 2 },
    116: { name: "Filigree (Undertide)",        breed: 21,  rarity: 4 },
    117: { name: "Circuit (Undertide)",         breed: 21,  rarity: 4 },
    118: { name: "Featherbeard (Undertide)",    breed: 21,  rarity: 3 },
    119: { name: "Remora (Undertide)",          breed: 21,  rarity: 4 },
    120: { name: "Ringlets (Undertide)",        breed: 21,  rarity: 2 },
    121: { name: "Ghost (Undertide)",           breed: 21,  rarity: 2 },
    122: { name: "Sparkle (Undertide)",         breed: 21,  rarity: 2 },
    123: { name: "Gembond (Undertide)",         breed: 21,  rarity: 2 },
    124: { name: "Soap (Undertide)",            breed: 21,  rarity: 4 },
    125: { name: "Tentacles (Undertide)",       breed: 21,  rarity: 4 },
    126: { name: "Nudibranch (Undertide)",      breed: 21,  rarity: 3 },
    127: { name: "Pufferfish (Undertide)",      breed: 21,  rarity: 2 },
    128: { name: "Plating (Undertide)",         breed: 21,  rarity: 2 },
    129: { name: "Okapi (Undertide)",           breed: 21,  rarity: 2 },
    130: { name: "Sailfin (Undertide)",         breed: 21,  rarity: 4 },
    131: { name: "Ghost (Veilspun)",            breed: 19,  rarity: 2 },
    132: { name: "Underbelly (Aberration)",     breed: 20,  rarity: 1 },
    133: { name: "Filigree (Veilspun)",         breed: 19,  rarity: 4 },
    134: { name: "Veined (Veilspun)",           breed: 19,  rarity: 3 },
    135: { name: "Circuit (Aether)",            breed: 22,  rarity: 4 },
    136: { name: "Contour (Aether)",            breed: 22,  rarity: 1 },
    137: { name: "Gembond (Aether)",            breed: 22,  rarity: 2 },
    138: { name: "Glowtail (Aether)",           breed: 22,  rarity: 4 },
    139: { name: "Keel (Aether)",               breed: 22,  rarity: 3 },
    140: { name: "Monarch (Aether)",            breed: 22,  rarity: 4 },
    141: { name: "Flutter (Aether)",            breed: 22,  rarity: 3 },
    142: { name: "Lace (Aether)",               breed: 22,  rarity: 2 },
    143: { name: "Mandibles (Aether)",          breed: 22,  rarity: 3 },
    144: { name: "Underbelly (Aether)",         breed: 22,  rarity: 1 },
    145: { name: "Stained (Aether)",            breed: 22,  rarity: 4 },
    146: { name: "Points (Aether)",             breed: 22,  rarity: 1 },
    147: { name: "Scales (Aether)",             breed: 22,  rarity: 3 },
    148: { name: "Stinger (Aether)",            breed: 22,  rarity: 2 },
    149: { name: "Space (Aether)",              breed: 22,  rarity: 4 },
    150: { name: "Smirch (Aether)",             breed: 22,  rarity: 3 },
    151: { name: "Smoke (Aether)",              breed: 22,  rarity: 2 },
    152: { name: "Sparkle (Aether)",            breed: 22,  rarity: 2 },
    153: { name: "Spines (Aether)",             breed: 22,  rarity: 2 },
    154: { name: "Whiskers (Aether)",           breed: 22,  rarity: 2 },
    155: { name: "Wish (Aether)",               breed: 22,  rarity: 4 },
    156: { name: "Angler (Gaoler)",             breed: 17,  rarity: 3 },
    157: { name: "Contour (Gaoler)",            breed: 17,  rarity: 1 },
    158: { name: "Monarch (Banescale)",         breed: 18,  rarity: 4 },
    159: { name: "Soap (Banescale)",            breed: 18,  rarity: 4 },
    160: { name: "Spines (Banescale)",          breed: 18,  rarity: 2 },
    161: { name: "Firebreather",                breed: 0,   rarity: 3 },
    162: { name: "Carnivore (Aberration)",      breed: 20,  rarity: 3 },
    163: { name: "Carnivore (Aether)",          breed: 22,  rarity: 3 },
    164: { name: "Carnivore (Banescale)",       breed: 18,  rarity: 3 },
    165: { name: "Carnivore (Undertide)",       breed: 21,  rarity: 3 },
    166: { name: "Carnivore (Gaoler)",          breed: 17,  rarity: 3 },
    167: { name: "Carnivore (Veilspun)",        breed: 19,  rarity: 3 },
    168: { name: "Polkadot",                    breed: 0,   rarity: 3 },
    169: { name: "Brightshine (Veilspun)",      breed: 19,  rarity: 3 },
    170: { name: "Brightshine (Undertide)",     breed: 21,  rarity: 3 },
    171: { name: "Underbelly (Sandsurge)",      breed: 23,  rarity: 1 },
    172: { name: "Stained (Sandsurge)",         breed: 23,  rarity: 4 },
    173: { name: "Augment (Sandsurge)",         breed: 23,  rarity: 4 },
    174: { name: "Beard (Sandsurge)",           breed: 23,  rarity: 2 },
    175: { name: "Kumo (Sandsurge)",            breed: 23,  rarity: 1 },
    176: { name: "Gembond (Sandsurge)",         breed: 23,  rarity: 2 },
    177: { name: "Darts (Sandsurge)",           breed: 23,  rarity: 1 },
    178: { name: "Runes (Sandsurge)",           breed: 23,  rarity: 2 },
    179: { name: "Thylacine (Sandsurge)",       breed: 23,  rarity: 1 },
    180: { name: "Soap (Sandsurge)",            breed: 23,  rarity: 4 },
    181: { name: "Spines (Sandsurge)",          breed: 23,  rarity: 2 },
    182: { name: "Okapi (Sandsurge)",           breed: 23,  rarity: 2 },
    183: { name: "Chitin (Sandsurge)",          breed: 23,  rarity: 1 },
    184: { name: "Crest (Sandsurge)",           breed: 23,  rarity: 2 },
    185: { name: "Fishbone (Sandsurge)",        breed: 23,  rarity: 2 },
    186: { name: "Keel (Sandsurge)",            breed: 23,  rarity: 3 },
    187: { name: "Lace (Sandsurge)",            breed: 23,  rarity: 2 },
    188: { name: "Spectre (Sandsurge)",         breed: 23,  rarity: 4 },
    189: { name: "Branches (Sandsurge)",        breed: 23,  rarity: 3 },
    190: { name: "Shark (Sandsurge)",           breed: 23,  rarity: 3 },
    191: { name: "Peacock (Sandsurge)",         breed: 23,  rarity: 1 },
    192: { name: "Smirch (Sandsurge)",          breed: 23,  rarity: 3 },
    193: { name: "Sparkle (Sandsurge)",         breed: 23,  rarity: 2 },
    194: { name: "Thundercrack (Sandsurge)",    breed: 23,  rarity: 3 },
    195: { name: "Thundercrack (Gaoler)",       breed: 17,  rarity: 3 },
    196: { name: "Flameforger (Banescale)",     breed: 18,  rarity: 3 },
    197: { name: "Flameforger (Aberration)",    breed: 20,  rarity: 3 },
    198: { name: "Augment (Aberration)",        breed: 20,  rarity: 4 },
    199: { name: "Braids (Aberration)",         breed: 20,  rarity: 2 },
    200: { name: "Contour (Aberration)",        breed: 20,  rarity: 1 },
    201: { name: "Fans (Aberration)",           breed: 20,  rarity: 4 },
    202: { name: "Firebreather (Aberration)",   breed: 20,  rarity: 3 },
    203: { name: "Koi (Aberration)",            breed: 20,  rarity: 4 },
    204: { name: "Skeletal (Aberration)",       breed: 20,  rarity: 3 },
    205: { name: "Smirch (Aberration)",         breed: 20,  rarity: 3 },
    206: { name: "Spines (Aberration)",         breed: 20,  rarity: 2 },
    207: { name: "Stained (Aberration)",        breed: 20,  rarity: 4 },
    208: { name: "Thorns (Aberration)",         breed: 20,  rarity: 2 },
    209: { name: "Starfall (Aether)",           breed: 22,  rarity: 3 },
    210: { name: "Starfall (Sandsurge)",        breed: 23,  rarity: 3 },
    211: { name: "Riot (Aberration)",           breed: 20,  rarity: 3 },
    212: { name: "Riot (Gaoler)",               breed: 17,  rarity: 3 },
    213: { name: "Wish",                        breed: 0,   rarity: 4 },
    214: { name: "Batty (Auraboa)",             breed: 24,  rarity: 3 },
    215: { name: "Crest (Auraboa)",             breed: 24,  rarity: 2 },
    216: { name: "Fishbone (Auraboa)",          breed: 24,  rarity: 2 },
    217: { name: "Branches (Auraboa)",          breed: 24,  rarity: 3 },
    218: { name: "Medusa (Auraboa)",            breed: 24,  rarity: 4 },
    219: { name: "Paradise (Auraboa)",          breed: 24,  rarity: 2 },
    220: { name: "Plumage (Auraboa)",           breed: 24,  rarity: 4 },
    221: { name: "Porcupine (Auraboa)",         breed: 24,  rarity: 3 },
    222: { name: "Sailfin (Auraboa)",           breed: 24,  rarity: 4 },
    223: { name: "Skuttle (Auraboa)",           breed: 24,  rarity: 3 },
    224: { name: "Stinger (Auraboa)",           breed: 24,  rarity: 2 },
    225: { name: "Terracotta (Auraboa)",        breed: 24,  rarity: 1 },
    226: { name: "Thorns (Auraboa)",            breed: 24,  rarity: 2 },
    227: { name: "Willow (Auraboa)",            breed: 24,  rarity: 2 },
    228: { name: "Underbelly (Auraboa)",        breed: 24,  rarity: 1 },
    229: { name: "Capsule (Auraboa)",           breed: 24,  rarity: 3 },
    230: { name: "Contour (Auraboa)",           breed: 24,  rarity: 1 },
    231: { name: "Crackle (Auraboa)",           breed: 24,  rarity: 2 },
    232: { name: "Firefly (Auraboa)",           breed: 24,  rarity: 3 },
    233: { name: "Firebreather (Auraboa)",      breed: 24,  rarity: 3 },
    234: { name: "Keel (Auraboa)",              breed: 24,  rarity: 3 },
    235: { name: "Koi (Auraboa)",               breed: 24,  rarity: 4 },
    236: { name: "Opal (Auraboa)",              breed: 24,  rarity: 4 },
    237: { name: "Peacock (Auraboa)",           breed: 24,  rarity: 1 },
    238: { name: "Polkadot (Auraboa)",          breed: 24,  rarity: 3 },
    239: { name: "Scales (Auraboa)",            breed: 24,  rarity: 3 },
    240: { name: "Smoke (Auraboa)",             breed: 24,  rarity: 2 },
    241: { name: "Spines (Auraboa)",            breed: 24,  rarity: 2 },
    242: { name: "Stained (Auraboa)",           breed: 24,  rarity: 4 },
    243: { name: "Topcoat (Auraboa)",           breed: 24,  rarity: 1 },
    244: { name: "Rockbreaker (Auraboa)",       breed: 24,  rarity: 3 },
    245: { name: "Rockbreaker (Sandsurge)",     breed: 23,  rarity: 3 },
    246: { name: "Angler (Undertide)",          breed: 21,  rarity: 3 },
    247: { name: "Opal (Undertide)",            breed: 21,  rarity: 4 },
    248: { name: "Branches (Undertide)",        breed: 21,  rarity: 3 },
    249: { name: "Contour (Undertide)",         breed: 21,  rarity: 1 },
    250: { name: "Crest (Undertide)",           breed: 21,  rarity: 2 },
    251: { name: "Fans (Undertide)",            breed: 21,  rarity: 4 },
    252: { name: "Firebreather (Undertide)",    breed: 21,  rarity: 3 },
    253: { name: "Firefly (Undertide)",         breed: 21,  rarity: 3 },
    254: { name: "Koi (Undertide)",             breed: 21,  rarity: 4 },
    255: { name: "Medusa (Undertide)",          breed: 21,  rarity: 4 },
    256: { name: "Porcupine (Undertide)",       breed: 21,  rarity: 3 },
    257: { name: "Scales (Undertide)",          breed: 21,  rarity: 3 },
    258: { name: "Shark (Undertide)",           breed: 21,  rarity: 3 },
    259: { name: "Smirch (Undertide)",          breed: 21,  rarity: 3 },
    260: { name: "Smoke (Undertide)",           breed: 21,  rarity: 2 },
    261: { name: "Spines (Undertide)",          breed: 21,  rarity: 2 },
    262: { name: "Stinger (Undertide)",         breed: 21,  rarity: 2 },
    263: { name: "Thorns (Undertide)",          breed: 21,  rarity: 2 },
    264: { name: "Topcoat (Undertide)",         breed: 21,  rarity: 1 },
    265: { name: "Crystalline (Gaoler)",        breed: 17,  rarity: 3 },
    266: { name: "Crystalline (Auraboa)",       breed: 24,  rarity: 3 },
    267: { name: "Blossom (Undertide)",         breed: 21,  rarity: 3 },
    268: { name: "Keel (Undertide)",            breed: 21,  rarity: 3 },
    269: { name: "Jellyfish (Undertide)",       breed: 21,  rarity: 2 },
    270: { name: "Trickmurk (Veilspun)",        breed: 19,  rarity: 3 },
    271: { name: "Trickmurk (Aether)",          breed: 22,  rarity: 3 },
    272: { name: "Mistral (Undertide)",         breed: 21,  rarity: 3 },
    273: { name: "Mistral (Banescale)",         breed: 18,  rarity: 3 },
    274: { name: "Stained (Dusthide)",          breed: 25,  rarity: 4 },
    275: { name: "Underbelly (Dusthide)",       breed: 25,  rarity: 1 },
    276: { name: "Antlers (Dusthide)",          breed: 25,  rarity: 2 },
    277: { name: "Fishbone (Dusthide)",         breed: 25,  rarity: 2 },
    278: { name: "Ghost (Dusthide)",            breed: 25,  rarity: 2 },
    279: { name: "Glowtail (Dusthide)",         breed: 25,  rarity: 4 },
    280: { name: "Augment (Dusthide)",          breed: 25,  rarity: 4 },
    281: { name: "Blossom (Dusthide)",          breed: 25,  rarity: 3 },
    282: { name: "Carnivore (Dusthide)",        breed: 25,  rarity: 3 },
    283: { name: "Mandibles (Dusthide)",        breed: 25,  rarity: 3 },
    284: { name: "Okapi (Dusthide)",            breed: 25,  rarity: 2 },
    285: { name: "Opal (Dusthide)",             breed: 25,  rarity: 4 },
    286: { name: "Angler (Dusthide)",           breed: 25,  rarity: 3 },
    287: { name: "Gembond (Dusthide)",          breed: 25,  rarity: 2 },
    288: { name: "Dewlap (Dusthide)",           breed: 25,  rarity: 3 },
    289: { name: "Batty (Dusthide)",            breed: 25,  rarity: 3 },
    290: { name: "Pachy (Dusthide)",            breed: 25,  rarity: 3 },
    291: { name: "Whiskers (Dusthide)",         breed: 25,  rarity: 2 },
    292: { name: "Sparkle (Dusthide)",          breed: 25,  rarity: 2 },
    293: { name: "Polkadot (Dusthide)",         breed: 25,  rarity: 3 },
    294: { name: "Ringlets (Dusthide)",         breed: 25,  rarity: 2 },
    295: { name: "Spores (Dusthide)",           breed: 25,  rarity: 4 },
    296: { name: "Veil (Dusthide)",             breed: 25,  rarity: 4 },
    297: { name: "Smoke (Dusthide)",            breed: 25,  rarity: 2 },
    298: { name: "Spines (Dusthide)",           breed: 25,  rarity: 2 },
    299: { name: "Topcoat (Dusthide)",          breed: 25,  rarity: 1 },
    300: { name: "Wavecrest (Dusthide)",        breed: 25,  rarity: 3 },
    301: { name: "Wavecrest (Undertide)",       breed: 21,  rarity: 1 },
    302: { name: "Firebreather (Veilspun)",     breed: 19,  rarity: 3 },
    303: { name: "Firebreather (Gaoler)",       breed: 17,  rarity: 3 },
    304: { name: "Firebreather (Banescale)",    breed: 18,  rarity: 3 },
    305: { name: "Firebreather (Sandsurge)",    breed: 23,  rarity: 3 },
    306: { name: "Firebreather (Aether)",       breed: 22,  rarity: 3 },
    307: { name: "Greenskeeper (Dusthide)",     breed: 25,  rarity: 3 },
    308: { name: "Greenskeeper (Auraboa)",      breed: 24,  rarity: 3 },
    309: { name: "Brightshine (Dusthide)",      breed: 25,  rarity: 3 },
    310: { name: "Brightshine (Banescale)",     breed: 18,  rarity: 3 },
    311: { name: "Thundercrack (Aberration)",   breed: 20,  rarity: 3 },
    312: { name: "Thundercrack (Veilspun)",     breed: 19,  rarity: 3 },
    313: { name: "Warrior (Banescale)",         breed: 18,  rarity: 3 },
    314: { name: "Warrior (Sandsurge)",         breed: 23,  rarity: 3 },
    315: { name: "Warrior (Veilspun)",          breed: 19,  rarity: 3 },
    316: { name: "Angler (Aether)",             breed: 22,  rarity: 4 },
    317: { name: "Braids (Aether)",             breed: 22,  rarity: 2 },
    318: { name: "Branches (Aether)",           breed: 22,  rarity: 3 },
    319: { name: "Capsule (Aether)",            breed: 22,  rarity: 3 },
    320: { name: "Crackle (Aether)",            breed: 22,  rarity: 2 },
    321: { name: "Diaphanous (Aether)",         breed: 22,  rarity: 4 },
    322: { name: "Fans (Aether)",               breed: 22,  rarity: 4 },
    323: { name: "Flecks (Aether)",             breed: 22,  rarity: 3 },
    324: { name: "Ghost (Aether)",              breed: 22,  rarity: 2 },
    325: { name: "Koi (Aether)",                breed: 22,  rarity: 4 },
    326: { name: "Medusa (Aether)",             breed: 22,  rarity: 4 },
    327: { name: "Nudibranch (Aether)",         breed: 22,  rarity: 4 },
    328: { name: "Firefly (Aether)",            breed: 22,  rarity: 3 },
    329: { name: "Ornaments (Aether)",          breed: 22,  rarity: 4 },
    330: { name: "Porcupine (Aether)",          breed: 22,  rarity: 3 },
    331: { name: "Sailfin (Aether)",            breed: 22,  rarity: 4 },
    332: { name: "Spores (Aether)",             breed: 22,  rarity: 4 },
    333: { name: "Thorns (Aether)",             breed: 22,  rarity: 2 },
    334: { name: "Thylacine (Aether)",          breed: 22,  rarity: 1 },
    335: { name: "Veined (Aether)",             breed: 22,  rarity: 3 },
    336: { name: "Blossom (Aether)",            breed: 22,  rarity: 3 },
    337: { name: "Fishbone (Aether)",           breed: 22,  rarity: 2 },
    338: { name: "Soap (Aether)",               breed: 22,  rarity: 4 },
    339: { name: "Tentacles (Aether)",          breed: 22,  rarity: 4 },
    340: { name: "Flameforger (Aether)",        breed: 22,  rarity: 3 },
    341: { name: "Flameforger (Sandsurge)",     breed: 23,  rarity: 3 },
    342: { name: "Starfall (Auraboa)",          breed: 24,  rarity: 3 },
    343: { name: "Starfall (Gaoler)",           breed: 17,  rarity: 3 },
    344: { name: "Thorns (Banescale)",          breed: 18,  rarity: 2 },
    345: { name: "Thorns (Dusthide)",           breed: 25,  rarity: 2 },
    346: { name: "Thorns (Gaoler)",             breed: 17,  rarity: 2 },
    347: { name: "Thorns (Sandsurge)",          breed: 23,  rarity: 2 },
    348: { name: "Riot (Veilspun)",             breed: 19,  rarity: 3 },
    349: { name: "Riot (Dusthide)",             breed: 25,  rarity: 3 },
    350: { name: "Augment (Everlux)",           breed: 26,  rarity: 4 },
    351: { name: "Batty (Everlux)",             breed: 26,  rarity: 3 },
    352: { name: "Chitin (Everlux)",            breed: 26,  rarity: 1 },
    353: { name: "Eclipse (Everlux)",           breed: 26,  rarity: 4 },
    361: { name: "Eclosion (Everlux)",          breed: 26,  rarity: 3 },
    354: { name: "Fishbone (Everlux)",          breed: 26,  rarity: 2 },
    355: { name: "Flutter (Everlux)",           breed: 26,  rarity: 3 },
    359: { name: "Gecko (Everlux)",             breed: 26,  rarity: 2 },
    362: { name: "Gembond (Everlux)",           breed: 26,  rarity: 2 },
    360: { name: "Gliders (Everlux)",           breed: 26,  rarity: 3 },
    356: { name: "Glitch (Everlux)",            breed: 26,  rarity: 4 },
    385: { name: "Harp (Everlux)",              breed: 26,  rarity: 4 },
    363: { name: "Jellyfish (Everlux)",         breed: 26,  rarity: 2 },
    364: { name: "Kumo (Everlux)",              breed: 26,  rarity: 1 },
    365: { name: "Mandibles (Everlux)",         breed: 26,  rarity: 3 },
    366: { name: "Nudibranch (Everlux)",        breed: 26,  rarity: 4 },
    367: { name: "Paradise (Everlux)",          breed: 26,  rarity: 2 },
    368: { name: "Peacock (Everlux)",           breed: 26,  rarity: 1 },
    369: { name: "Points (Everlux)",            breed: 26,  rarity: 1 },
    370: { name: "Polkadot (Everlux)",          breed: 26,  rarity: 3 },
    371: { name: "Rift (Everlux)",              breed: 26,  rarity: 2 },
    372: { name: "Runes (Everlux)",             breed: 26,  rarity: 2 },
    373: { name: "Scales (Everlux)",            breed: 26,  rarity: 3 },
    374: { name: "Skuttle (Everlux)",           breed: 26,  rarity: 3 },
    375: { name: "Smoke (Everlux)",             breed: 26,  rarity: 2 },
    376: { name: "Space (Everlux)",             breed: 26,  rarity: 4 },
    377: { name: "Sparkle (Everlux)",           breed: 26,  rarity: 2 },
    378: { name: "Spines (Everlux)",            breed: 26,  rarity: 2 },
    379: { name: "Spores (Everlux)",            breed: 26,  rarity: 4 },
    357: { name: "Stained (Everlux)",           breed: 26,  rarity: 4 },
    380: { name: "Sunsail (Everlux)",           breed: 26,  rarity: 2 },
    381: { name: "Thorns (Everlux)",            breed: 26,  rarity: 2 },
    358: { name: "Underbelly (Everlux)",        breed: 26,  rarity: 1 },
    382: { name: "Whiskers (Everlux)",          breed: 26,  rarity: 2 },
    383: { name: "Wish (Everlux)",              breed: 26,  rarity: 4 },
    384: { name: "Wool (Everlux)",              breed: 26,  rarity: 1 },
}

export const colours = {
    1:   { name:"Maize",       id: 1,       hexcode: "#FFFDEA", text: "black"},		
    2:   { name:"Cream",       id: 163,     hexcode: "#FFEFDC", text: "black"},	
    3:   { name:"Antique",     id: 97,      hexcode: "#D8D6CD", text: "black"},	
    4:   { name:"White",       id: 2,       hexcode: "#FFFFFF", text: "black"},	
    5:   { name:"Moon",        id: 74,      hexcode: "#D8D7D8", text: "black"},	    
    6:   { name:"Ice",         id: 3,       hexcode: "#F1F3FF", text: "black"},
    7:   { name:"Orca",        id: 131,     hexcode: "#E1DFFF", text: "black"},
    8:   { name:"Platinum",    id: 4,       hexcode: "#C8BECE", text: "black"},
    9:   { name:"Silver",      id: 5,       hexcode: "#BBBABF", text: "black"},	
    10:  { name:"Dust",        id: 146,     hexcode: "#9D9D9F", text: "black"},		
    11:  { name:"Grey",        id: 6,       hexcode: "#808080", text: "white"},	
    12:  { name:"Smoke",       id: 91,      hexcode: "#9494A9", text: "black"},	
    13:  { name:"Gloom",       id: 98,      hexcode: "#545365", text: "white"},	
    14:  { name:"Lead",        id: 118,     hexcode: "#413C40", text: "white"},	
    15:  { name:"Shale",       id: 177,     hexcode: "#4D484F", text: "white"},	
    16:  { name:"Flint",       id: 129,     hexcode: "#636268", text: "white"},	
    17:  { name:"Charcoal",    id: 7,       hexcode: "#555555", text: "white"},	
    18:  { name:"Coal",        id: 8,       hexcode: "#4B4946", text: "white"},	
    19:  { name:"Oilslick",    id: 70,      hexcode: "#352C27", text: "white"},		
    20:  { name:"Black",       id: 9,       hexcode: "#333333", text: "white"},	
    21:  { name:"Obsidian",    id: 10,      hexcode: "#1D2224", text: "white"},	
    22:  { name:"Eldritch",    id: 176,     hexcode: "#252A24", text: "white"},	
    23:  { name:"Midnight",    id: 11,      hexcode: "#292B38", text: "white"},	
    24:  { name:"Shadow",      id: 12,      hexcode: "#3A2E44", text: "white"},	
    25:  { name:"Blackberry",  id: 127,     hexcode: "#4C2A4F", text: "white"},	
    26:  { name:"Mulberry",    id: 13,      hexcode: "#6E235D", text: "white"},	
    27:  { name:"Plum",        id: 92,      hexcode: "#863290", text: "white"},	
    28:  { name:"Wisteria",    id: 119,     hexcode: "#724D79", text: "white"},	
    29:  { name:"Thistle",     id: 14,      hexcode: "#8F7C8B", text: "black"},	
    30:  { name:"Fog",         id: 137,     hexcode: "#A794B2", text: "black"},	
    31:  { name:"Mist",        id: 150,     hexcode: "#E1CDFE", text: "black"},	
    32:  { name:"Lavender",    id: 15,      hexcode: "#CCA4E0", text: "black"},	
    33:  { name:"Heather",     id: 68,      hexcode: "#9778BE", text: "black"},		
    34:  { name:"Purple",      id: 16,      hexcode: "#A261CF", text: "black"},	
    35:  { name:"Orchid",      id: 69,      hexcode: "#DA4FFF", text: "black"},	
    36:  { name:"Amethyst",    id: 114,     hexcode: "#993BD1", text: "black"},	
    37:  { name:"Nightshade",  id: 175,     hexcode: "#7930B5", text: "white"},	
    38:  { name:"Violet",      id: 17,      hexcode: "#643F9C", text: "white"},	
    39:  { name:"Grape",       id: 147,     hexcode: "#580FC0", text: "white"},	
    40:  { name:"Royal",       id: 18,      hexcode: "#4D2C89", text: "white"},	
    41:  { name:"Eggplant",    id: 111,     hexcode: "#3F2B66", text: "white"},	
    42:  { name:"Iris",        id: 82,      hexcode: "#525195", text: "white"},	
    43:  { name:"Storm",       id: 19,      hexcode: "#757ADB", text: "black"},	
    44:  { name:"Twilight",    id: 174,     hexcode: "#484AA1", text: "white"},	
    45:  { name:"Indigo",      id: 112,     hexcode: "#2D237A", text: "white"},	
    46:  { name:"Sapphire",    id: 71,      hexcode: "#0D0A5B", text: "white"},	
    47:  { name:"Navy",        id: 20,      hexcode: "#212B5F", text: "white"},	
    48:  { name:"Cobalt",      id: 136,     hexcode: "#013485", text: "white"},	
    49:  { name:"Ultramarine", id: 90,      hexcode: "#1C51E7", text: "white"},	
    50:  { name:"Blue",        id: 21,      hexcode: "#324BA9", text: "white"},	
    51:  { name:"Periwinkle",  id: 135,     hexcode: "#4866D5", text: "black"},	
    52:  { name:"Lapis",       id: 148,     hexcode: "#2F83FF", text: "black"},	
    53:  { name:"Splash",      id: 22,      hexcode: "#6394DD", text: "black"},	
    54:  { name:"Cornflower",  id: 145,     hexcode: "#76A8FF", text: "black"},	
    55:  { name:"Sky",         id: 23,      hexcode: "#AEC8FF", text: "black"},	
    56:  { name:"Stonewash",   id: 24,      hexcode: "#7895C1", text: "black"},	
    57:  { name:"Overcast",    id: 126,     hexcode: "#444F69", text: "white"},	
    58:  { name:"Steel",       id: 25,      hexcode: "#556979", text: "white"},	
    59:  { name:"Denim",       id: 26,      hexcode: "#2F4557", text: "white"},	
    60:  { name:"Abyss",       id: 96,      hexcode: "#0D1E25", text: "white"},	
    61:  { name:"Phthalo",     id: 151,     hexcode: "#0B2D46", text: "white"},	
    62:  { name:"Azure",       id: 27,      hexcode: "#0A3D67", text: "white"},	
    63:  { name:"Caribbean",   id: 28,      hexcode: "#0086CE", text: "black"},	
    64:  { name:"Teal",        id: 29,      hexcode: "#2B768F", text: "black"},	
    65:  { name:"Cerulean",    id: 117,     hexcode: "#00B4D5", text: "black"},	
    66:  { name:"Cyan",        id: 89,      hexcode: "#00FFF1", text: "black"},		
    67:  { name:"Robin",       id: 99,      hexcode: "#9AEAEF", text: "black"},	
    68:  { name:"Aqua",        id: 30,      hexcode: "#72C4C4", text: "black"},	
    69:  { name:"Turquoise",   id: 149,     hexcode: "#3CA2A4", text: "black"},	
    70:  { name:"Spruce",      id: 100,     hexcode: "#8DBCB4", text: "black"},	
    71:  { name:"Pistachio",   id: 125,     hexcode: "#E2FFE6", text: "black"},	
    72:  { name:"Seafoam",     id: 31,      hexcode: "#9AFFC7", text: "black"},
    73:  { name:"Mint",        id: 152,     hexcode: "#9AFFC7", text: "black"},	
    74:  { name:"Jade",        id: 32,      hexcode: "#61AB89", text: "black"},	
    75:  { name:"Spearmint",   id: 78,      hexcode: "#148E67", text: "black"},	
    76:  { name:"Thicket",     id: 141,     hexcode: "#005D48", text: "white"},		
    77:  { name:"Peacock",     id: 134,     hexcode: "#1F483A", text: "white"},	
    78:  { name:"Emerald",     id: 33,      hexcode: "#20603F", text: "white"},	
    79:  { name:"Shamrock",    id: 80,      hexcode: "#236825", text: "white"},		
    80:  { name:"Jungle",      id: 34,      hexcode: "#1E361A", text: "white"},	
    81:  { name:"Hunter",      id: 81,      hexcode: "#1E2716", text: "white"},	
    82:  { name:"Forest",      id: 35,      hexcode: "#425035", text: "white"},	
    83:  { name:"Camo",        id: 154,     hexcode: "#51684C", text: "white"},	
    84:  { name:"Algae",       id: 153,     hexcode: "#97AF8B", text: "black"},	
    85:  { name:"Swamp",       id: 36,      hexcode: "#687F67", text: "white"},	
    86:  { name:"Avocado",     id: 37,      hexcode: "#567C34", text: "white"},	
    87:  { name:"Green",       id: 38,      hexcode: "#629C3F", text: "white"},	
    88:  { name:"Fern",        id: 113,     hexcode: "#7ECE73", text: "black"},	
    89:  { name:"Mantis",      id: 79,      hexcode: "#9BFF9D", text: "black"},	
    90:  { name:"Pear",        id: 101,     hexcode: "#8ECE56", text: "black"},	
    91:  { name:"Leaf",        id: 39,      hexcode: "#A5E32D", text: "black"},	
    92:  { name:"Radioactive", id: 130,     hexcode: "#C6FF00", text: "black"},	
    93:  { name:"Honeydew",    id: 102,     hexcode: "#D1E572", text: "black"},	
    94:  { name:"Peridot",     id: 144,     hexcode: "#E8FCB4", text: "black"},	
    95:  { name:"Chartreuse",  id: 155,     hexcode: "#B4CD3D", text: "black"},	
    96:  { name:"Spring",      id: 40,      hexcode: "#A9A032", text: "black"},	
    97:  { name:"Crocodile",   id: 173,     hexcode: "#828335", text: "black"},	
    98:  { name:"Olive",       id: 123,     hexcode: "#697135", text: "white"},	
    99:  { name:"Murk",        id: 142,     hexcode: "#4B4420", text: "white"},	
    100: { name:"Moss",        id: 115,     hexcode: "#7E7645", text: "white"},	
    101: { name:"Goldenrod",   id: 41,      hexcode: "#BEA55D", text: "black"},	
    102: { name:"Amber",       id: 103,     hexcode: "#C18E1B", text: "black"},	
    103: { name:"Honey",       id: 93,      hexcode: "#D1B300", text: "black"},	
    104: { name:"Lemon",       id: 42,      hexcode: "#FFE63B", text: "black"},	
    105: { name:"Yellow",      id: 104,     hexcode: "#F9E255", text: "black"},	
    106: { name:"Grapefruit",  id: 128,     hexcode: "#F7FF6F", text: "black"},	
    107: { name:"Banana",      id: 43,      hexcode: "#FFEC80", text: "black"},	
    108: { name:"Sanddollar",  id: 110,     hexcode: "#EDE8B0", text: "black"},	
    109: { name:"Flaxen",      id: 139,     hexcode: "#FDE9AC", text: "black"},	
    110: { name:"Ivory",       id: 44,      hexcode: "#FFD297", text: "black"},	
    111: { name:"Buttercup",   id: 167,     hexcode: "#F6BF6C", text: "black"},	
    112: { name:"Gold",        id: 45,      hexcode: "#E8AF49", text: "black"},	
    113: { name:"Metals",      id: 140,     hexcode: "#D1B045", text: "black"},	
    114: { name:"Marigold",    id: 75,      hexcode: "#FFB53C", text: "black"},	
    115: { name:"Sunshine",    id: 46,      hexcode: "#FA912B", text: "black"},	
    116: { name:"Saffron",     id: 84,      hexcode: "#FF8500", text: "black"},	
    117: { name:"Sunset",      id: 172,     hexcode: "#FFA147", text: "black"},	
    118: { name:"Peach",       id: 105,     hexcode: "#FFB576", text: "black"},	
    119: { name:"Cantaloupe",  id: 171,     hexcode: "#FF984F", text: "black"},		
    120: { name:"Orange",      id: 47,      hexcode: "#D5602B", text: "black"},	
    121: { name:"Bronze",      id: 83,      hexcode: "#B2560D", text: "black"},	
    122: { name:"Terracotta",  id: 108,     hexcode: "#B24407", text: "black"},	
    123: { name:"Carrot",      id: 133,     hexcode: "#FF5500", text: "black"},	
    124: { name:"Fire",        id: 48,      hexcode: "#EF5C23", text: "black"},	
    125: { name:"Pumpkin",     id: 158,     hexcode: "#FF6841", text: "black"},	
    126: { name:"Tangerine",   id: 49,      hexcode: "#FF7360", text: "black"},	
    127: { name:"Cinnamon",    id: 77,      hexcode: "#C15A39", text: "black"},	
    128: { name:"Caramel",     id: 156,     hexcode: "#C47149", text: "black"},	
    129: { name:"Sand",        id: 50,      hexcode: "#B27749", text: "black"},	
    130: { name:"Tan",         id: 76,      hexcode: "#C3996F", text: "black"},	
    131: { name:"Beige",       id: 51,      hexcode: "#CABBA2", text: "black"},	
    132: { name:"Stone",       id: 52,      hexcode: "#827A64", text: "black"},	
    133: { name:"Taupe",       id: 95,      hexcode: "#6D675B", text: "white"},	
    134: { name:"Slate",       id: 53,      hexcode: "#564D48", text: "white"},	
    135: { name:"Driftwood",   id: 165,     hexcode: "#766259", text: "white"},	
    136: { name:"Latte",       id: 143,     hexcode: "#977B6C", text: "black"},	
    137: { name:"Dirt",        id: 162,     hexcode: "#774840", text: "white"},	
    138: { name:"Clay",        id: 106,     hexcode: "#603E3D", text: "white"},	
    139: { name:"Sable",       id: 138,     hexcode: "#57372C", text: "white"},	
    140: { name:"Umber",       id: 157,     hexcode: "#301E1A", text: "white"},	
    141: { name:"Soil",        id: 54,      hexcode: "#5A4534", text: "white"},	
    142: { name:"Hickory",     id: 88,      hexcode: "#72573A", text: "white"},	
    143: { name:"Tarnish",     id: 124,     hexcode: "#855B33", text: "white"},	
    144: { name:"Ginger",      id: 122,     hexcode: "#91532A", text: "white"},	
    145: { name:"Brown",       id: 55,      hexcode: "#8E5B3F", text: "white"},	
    146: { name:"Chocolate",   id: 56,      hexcode: "#563012", text: "white"},	
    147: { name:"Auburn",      id: 166,     hexcode: "#7B3C1D", text: "white"},	
    148: { name:"Copper",      id: 94,      hexcode: "#A44B28", text: "white"},	
    149: { name:"Rust",        id: 57,      hexcode: "#8B3220", text: "white"},	
    150: { name:"Tomato",      id: 58,      hexcode: "#BA311C", text: "black"},	
    151: { name:"Vermilion",   id: 169,     hexcode: "#E22D18", text: "black"},	
    152: { name:"Ruby",        id: 86,      hexcode: "#CE000D", text: "black"},	
    153: { name:"Cherry",      id: 116,     hexcode: "#AA0024", text: "white"},	
    154: { name:"Crimson",     id: 59,      hexcode: "#850012", text: "white"},	
    155: { name:"Garnet",      id: 161,     hexcode: "#581014", text: "white"},	
    156: { name:"Sanguine",    id: 121,     hexcode: "#2D0102", text: "white"},	
    157: { name:"Blood",       id: 60,      hexcode: "#451717", text: "white"},	
    158: { name:"Maroon",      id: 61,      hexcode: "#652127", text: "white"},	
    159: { name:"Berry",       id: 87,      hexcode: "#8C272D", text: "white"},	
    160: { name:"Red",         id: 62,      hexcode: "#C1272D", text: "black"},	
    161: { name:"Strawberry",  id: 168,     hexcode: "#DF3236", text: "black"},	
    162: { name:"Cerise",      id: 132,     hexcode: "#A12928", text: "white"},	
    163: { name:"Carmine",     id: 63,      hexcode: "#B13A3A", text: "white"},	
    164: { name:"Brick",       id: 107,     hexcode: "#9A534D", text: "white"},	
    165: { name:"Coral",       id: 64,      hexcode: "#CC6F6F", text: "black"},	
    166: { name:"Blush",       id: 159,     hexcode: "#FEA0A0", text: "black"},	
    167: { name:"Cottoncandy", id: 164,     hexcode: "#EB799A", text: "black"},	
    168: { name:"Watermelon",  id: 120,     hexcode: "#DB518D", text: "black"},	
    169: { name:"Magenta",     id: 65,      hexcode: "#E934AA", text: "black"},	
    170: { name:"Fuchsia",     id: 170,     hexcode: "#E7008B", text: "black"},	
    171: { name:"Raspberry",   id: 160,     hexcode: "#8A024A", text: "white"},	
    172: { name:"Wine",        id: 72,      hexcode: "#4D0F28", text: "white"},	
    173: { name:"Mauve",       id: 73,      hexcode: "#9C4975", text: "white"},	
    174: { name:"Pink",        id: 66,      hexcode: "#E77FBF", text: "black"},	
    175: { name:"Bubblegum",   id: 109,     hexcode: "#E5A9FF", text: "black"},	
    176: { name:"Rose",        id: 67,      hexcode: "#FFD6F6", text: "black"},	
    177: { name:"Pearl",       id: 85,      hexcode: "#FBEDFA", text: "black"},   
};

export const elements = [
    "Earth",
    "Plague",
    "Wind",
    "Water",
    "Lightning",
    "Ice",
    "Shadow",
    "Light",
    "Arcane",
    "Nature",
    "Fire",
];

export const eyeTypes = [
    "Common",
    "Uncommon",
    "Unusual",
    "Rare",
    "Faceted",
    "Multi-Gaze",
    "Primal",
    "Glowing",
    "Dark Sclera",
    "Goat",
    "Swirl",
    "Innocent",
    "Pastel",
    "Bright",
    "Dark",
    "Faded"
]

export const searchPatterns = [
    "XXX",
    "XXY",
    "XYX",
    "XYY",
    "XYZ"
]